import { useEffect, useState, useRef } from 'react';

import { getBreakpointScreenSize, findBreakpoint } from '../utils/breakpoints';
import { getScreenSizes } from '../utils/get-screen-sizes';
import { useDimensions } from './useDimensions';

function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

export const useBreakpoints = ({ breakpoints, defaultBreakPoint } = {}) => {
	const { width } = useDimensions();
	const [breakpoint, setBreakpoint] = useState(null);
	const prevBreakpoints = usePrevious(breakpoints);

	useEffect(() => {
		if (breakpoints && width) {
			const screenSizes = getScreenSizes(width);
			const size = getBreakpointScreenSize(screenSizes);
			const activeBreakpoint = findBreakpoint(breakpoints, { size, defaultBreakPoint });

			setBreakpoint(activeBreakpoint);
		}
	}, [width, JSON.stringify(prevBreakpoints) !== JSON.stringify(breakpoints)]);

	return {
		breakpoint,
		windowSize: width,
	};
};
