import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Dompurify from 'dompurify';
import { Alert, Label, Select } from '@a-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { getDuration } from 'actions/Duration';
import { getAppointments } from 'actions/Appointments';

import FormNavigation from '../FormNavigation';

const DurationStep = ({
  product,
  index,
  mode,
  previous,
  next,
  onChange,
  appointmentType,
  stepData = {},
  steps
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();

  const [valid, setValid] = useState(true);
  const [amount, setAmount] = useState(stepData.amount || 1);
  const [duration, setDuration] = useState(stepData.duration | null);
  const [hasDoubleAppointment, setHasDoubleAppointment] = useState(false);
  const [activeAppointments, setActiveAppointments] = useState([]);

  const { durationApi } = useSelector((state) => ({
    durationApi: {
      loading: state.durationReducer.loading,
      error: state.durationReducer.error,
      data: state.durationReducer.duration
    },
  }));

  const checkOtherAppointmentsForProduct = appointments => {
    const now = new Date().toISOString();

    return [
      ...appointments.filter((appointment) => {
        return appointment.product.id === product.id && appointment.startTime > now;
      })
    ];
  }

  useEffect(() => {
    setDuration(durationApi.data);
  }, [durationApi.data]);

  useEffect(() => {
    setValid(true);
    dispatch(getDuration(product.id, amount));
    dispatch(getAppointments()).then(({ payload: { appointments }}) => {
      const activeAppointments = checkOtherAppointmentsForProduct(appointments);

      if (activeAppointments.length > 0) {
        setHasDoubleAppointment(true);
        setActiveAppointments(activeAppointments);
      }
    });
  }, []);

  const goToNextStep = () => {
    onChange('duration', {
      amount,
      duration
    });
    next();
  };

  const onSelect = (e) => {
    const value = e.target.value;
    setAmount(value);
    dispatch(getDuration(product.id, value));
  }

  const createAmountList = () => {
    const amounts = [];
    const maxAmount = product.formConfig.maxAmount || 10;

    for (let index = 1; index <= maxAmount; index++) {
      amounts.push({
        key: index,
        value: index,
        label: index
      });
    }

    return amounts;
  };

  const goToAppointmentDetail = () => {
    const activeAppointment = activeAppointments[0];
    navigate(`/afspraak/${activeAppointment.id}${search}`);
  };

  if (product.redirectTo) window.location.href = product.redirectTo;

  return (
    <div className="u-margin-top">
      {mode !== 'kiosk' &&
        <div className="u-margin-top u-margin-bottom title-appointment"><Label
          type="primary"
        >{product.name}
        </Label>
        </div>
      }

      {product && product.formConfig?.locationWarning && (
          <div className="u-margin-top u-margin-bottom">
            <Alert theme="warning" inline={true}>
              <div dangerouslySetInnerHTML={{ __html: Dompurify.sanitize(product.formConfig.locationWarning)}} />
            </Alert>
          </div>
      )}

      {(hasDoubleAppointment && appointmentType !== 'edit') && <div className="u-margin-bottom"><Alert
        ariaLabelClose="Close"
        confirmButton={{
          children: 'Bekijk je afspraak',
          id: 'title2-aui-alert-confirm',
          onClick: () => goToAppointmentDetail(),
          size: 'small',
          theme: 'warning'
        }}
        onClose={() => setHasDoubleAppointment(false)}
        theme="warning"
        title={{
          label: 'Je hebt al een afspraak'
        }}
        titleId="aui-alert-title"
      >
      We zien dat je hiervoor al een afspraak hebt.
      </Alert></div>
      }

      {mode !== 'kiosk' &&
        <h3>Aantal</h3>
      }

      <div className="u-margin-top u-margin-bottom-xl">
        <Select
          label={product?.formConfig?.labels?.amount || ''}
          placeholder={'Maak een keuze'}
          options={createAmountList()}
          value={amount}
          name="amount"
          required
          onChange={onSelect}
        />
      </div>

      <FormNavigation current={index} valid={valid} previous={previous} next={goToNextStep} steps={steps} />
    </div>
  );
};

export default DurationStep;
