import { clone, lensPath, mergeAll, pathOr, set } from 'ramda';

import { getActionConfig } from '../utils/action';
import { isStatePath } from '../utils/state-path';
import { isObject } from '../utils/is-object';

export class Reducer {
	static identifier = 'Reducer';

	mergeStrategies = {
		OVERWRITE: this.overwrite,
		APPEND: this.append,
		MERGE: this.merge,
	}
	actions;

	constructor(actions) {
		this.actions = actions;
	}

	reduce(currentState, action) {
		const clonedCurrentState = clone(currentState.getValue());
		// Get reducer action matching emitted action
		const reducerActionConfig = getActionConfig(this.actions, action);

		// Check if selector matches item in current State
		if (!isStatePath(clonedCurrentState, reducerActionConfig.reducer.selector)) {
			console.warn(`Selector (${reducerActionConfig.reducer.selector}) does not exist for "${Reducer.identifier}"!`);
			return clonedCurrentState;
		}

		// Check Merge Strategy -> determine state change based on the given strategy
		// mergeStrategies:
		// - OVERWRITE = the state is overwritten with whatever enters the reducer
		// - APPEND = the state is supplemented with what enters the reducer
		// - MERGE = the state is merged with what enters the reducer
		// - - in case of an ARRAY -> APPEND
		// - - in case of an object -> MERGED
		// - - in case of primitive values -> OVERWRITE
		// ==========================================================================
		const mergeStrategy = reducerActionConfig.reducer.mergeStrategy;
		if (this.mergeStrategies[mergeStrategy]) {
			return this.mergeStrategies[mergeStrategy](clonedCurrentState, action, reducerActionConfig);
		}

		// return current state
		return clonedCurrentState;
	}

	overwrite(currentState, action, reducerActionConfig) {
		return set(
			lensPath(reducerActionConfig.reducer.selector),
			action.result,
			currentState
		);
	}

	append(currentState, action, reducerActionConfig) {
		// Get current state data
		const currentStateData = pathOr([], reducerActionConfig.reducer.selector, currentState);
		// Set new result
		return set(
			lensPath(reducerActionConfig.reducer.selector),
			[...(currentStateData || []), ...(action.result || [])],
			currentState
		);
	}

	merge(currentState, action, reducerActionConfig) {
		if (isObject(action.result)) {
			// Get current state datas
			const currentStateData = pathOr({}, reducerActionConfig.reducer.selector, currentState);
			// Set new result
			return set(
				lensPath(reducerActionConfig.reducer.selector),
				mergeAll([currentStateData, action.result]),
				currentState
			);
		}

		if (Array.isArray(action.result)) {
			return this.append(currentState, action, reducerActionConfig);
		}

		return this.overwrite(currentState, action, reducerActionConfig);
	}
}
