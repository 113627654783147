import { fetchLocations } from '../api/Locations';
import { fetchLocationsBegin, fetchLocationsSuccess, fetchLocationsFailure } from './types';

export const getLocations = (id) => (dispatch) => {
  dispatch(fetchLocationsBegin());
  return fetchLocations(id).then(
    (data) => dispatch(fetchLocationsSuccess(data)),
    (err) => dispatch(fetchLocationsFailure(err))
  );
};
