import ErrorHandler from './ErrorHandler';

export function fetchProducts() {
  return fetch('/api/products', { method: 'GET' })
    .then(ErrorHandler)
    .then(response => response.json());
}

export function fetchProductBySlug(slug) {
  return fetch(`/api/products/${slug}`, { method: 'GET' })
    .then(ErrorHandler)
    .then(response => response.json());
}
