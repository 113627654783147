import { Component } from 'react';
import { Button, TextField } from '@a-ui/react';
import classNames from 'classnames';

import { getAstadUrl } from 'hooks/config';

const INVALID_DATE_ERROR = 'Gelieve een geldig e-mail in te vullen';

class EmailOverlay extends Component {
  state = {
    email: this.props.email || '',
    error: this.props.error || ''
  };

  onKeyPress = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    if (e.keyCode === 13) {
      this.validateEmail(this.state.email);
    }
  };

  validateEmail = (email) => {
    if (!email) {
      this.setState({ error: INVALID_DATE_ERROR });
    } else {
      this.props.submitEmail(email);
    }
  };

  render() {
    const { email, error } = this.state;
    const overlayClass = classNames('m-overlay', 'is-active');

    return (
      <div className="popup">
        <div className={overlayClass}>
          <div className="m-overlay__inner">
            <Button
              onClick={() => {
                window.location.href = getAstadUrl();
              }}
              addOn={{
                type: 'icon',
                iconProps: {
                  name: 'remove'
                }
              }}
              emphasis="low"
            />
            <div className="m-modal__body u-margin-bottom">
              <p>Geef het e-mailadres op waarmee u de afspraak heeft gemaakt. Zo kunnen wij uw afspraak terugvinden.</p>
              <div className="u-margin-top">
                <TextField
                  required
                  name="email"
                  label={'E-mail:'}
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  onKeyDown={(e) => this.onKeyPress(e)}
                  id={'textfield-email'}
                  className={'u-margin-top-xs'}
                />
              </div>
              {error && <div className="u-text-danger u-margin-top-xs u-margin-bottom">{error}</div>}
            </div>
            <div className="m-modal__footer">
              <Button
                id={'button-submit-appointment'}
                onClick={() => {
                  this.validateEmail(email);
                }}
              >
                Verdergaan
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EmailOverlay;
