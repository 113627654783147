import * as sessionApi from '../api/Session';

export const FETCH_SESSION_BEGIN = 'FETCH_SESSION_BEGIN';
export const FETCH_SESSION_SUCCESS = 'FETCH_SESSION_SUCCESS';
export const FETCH_SESSION_FAILURE = 'FETCH_SESSION_FAILURE';

export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_BEGIN = 'LOGOUT_BEGIN';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

const fetchSessionSuccess = (session) => ({
  type: FETCH_SESSION_SUCCESS,
  session
});

const fetchSessionFailure = (error) => ({
  type: FETCH_SESSION_FAILURE,
  error
});

const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS
});

const logoutFailure = () => ({
  type: LOGOUT_FAILURE
});

export const fetchSession = () => (dispatch) => {
  dispatch({ type: FETCH_SESSION_BEGIN });
  return sessionApi
    .isLoggedin()
    .then((session) => dispatch(fetchSessionSuccess(session)))
    .catch((err) => dispatch(fetchSessionFailure(err)));
};

export const login = () => {
  const loginUrl =
    process.env.NODE_ENV === 'development'
      ? `http://localhost:2000/login?fromUrl=${window.location.href}`
      : `/login?fromUrl=${window.location.href}`;
  sessionApi.isLoggedin().then((response) => {
    if (!response.isLoggedin) {
      window.location.href = loginUrl;
    }
  });
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT_BEGIN });
  return sessionApi
    .logout()
    .then(() => dispatch(logoutSuccess()))
    .catch(() => dispatch(logoutFailure()));
};
