const getEnv = () => {
  if (/^(.*)-o\.antwerpen\.be/i.test(window.location.origin)) {
    return 'development';
  }
  if (/^(.*)-a\.antwerpen\.be/i.test(window.location.origin)) {
    return 'acceptance';
  }
  if (/^(.*)\.antwerpen\.be/i.test(window.location.origin)) {
    return 'production';
  }

  return 'development';
};

const useConfig = () => {
  const config = {
    development: {
      ANTWERPEN_BE_URL: 'https://www.dev.dcs.antwerpen.be/producten',
      ANTWERPEN_CYBER_URL: 'https://www.dev.dcs.antwerpen.be/info/afspraken-maken-impact-cyberaanval',
    },
    acceptance: {
      ANTWERPEN_BE_URL: 'https://www.acc.dcs.antwerpen.be/producten',
      ANTWERPEN_CYBER_URL: 'https://www.acc.dcs.antwerpen.be/info/afspraken-maken-impact-cyberaanval',
    },
    production: {
      ANTWERPEN_BE_URL: 'https://www.antwerpen.be/producten',
      ANTWERPEN_CYBER_URL: 'https://www.antwerpen.be/info/afspraken-maken-impact-cyberaanval',
    },
  };

  return config[getEnv()];
};

export default useConfig;