import { createContext, useState, useEffect } from 'react';

const ISLOGGEDIN_ENDPOINT = '/auth/isloggedin';

export const UserContext = createContext();

export const UserProvider = props => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState(null);

  useEffect(
    () => {
      const fetchUser = async () => {
        if (loading) return;

        try {
          setLoading(true);
          const response = await fetch(ISLOGGEDIN_ENDPOINT);
          const { isLoggedin, user: dataUser } = await response.json();

          setIsLoggedIn(isLoggedin);
          setUser({...dataUser?.dataSources?.aprofiel, ...{ birthDate: dataUser?.dataSources?.crspersoon.birthDate, assuranceLevel: dataUser?.assuranceLevel }});
          setLoading(false);
        } catch (err) {
          setError(err);
          setLoading(false);
        }
      };

      fetchUser();
    },
    [],
  );
  return (
    <UserContext.Provider value={{
      loading,
      user,
      error,
      isLoggedIn,
    }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
