//import { useRef } from 'react';
//import useSize from '@react-hook/size';
import classNames from 'classnames';
import { Spinner } from '@a-ui/react';

import useAppModus from 'hooks/useAppModus';
import { notificationSettings } from './Notifications/settings';

import { default as AppHeader } from './Header';
import { default as AppFooter } from './Footer';

const HeaderWrapper = () => {
  const mode = useAppModus();

  return (
    <div className={'no-print'}>
      {mode === 'web' && <AppHeader />}
    </div>
  );
};

const Notifications = () => <div id={notificationSettings.wrapperId}></div>;

const Content = ({ children }) => {
  const mode = useAppModus();

  const contentClass = classNames('u-container', {
    'u-margin-top-3xl': mode === 'web',
    'u-margin-bottom-3xl': mode === 'web',
    'u-margin-bottom-xl': mode !== 'web',
  });

  return (
    <div id="main">
      <div className={contentClass} role="main">
        <div className="row">
          <div className="col-xs-12 col-md-8" style={{ margin: 'auto' }}>{children}</div>
        </div>
      </div>
    </div>
  );
};

const Layout = ({ style, className, children }) => {
  const layoutStyle = Object.assign(
    { display: 'flex', flexDirection: 'column', height: '100%' },
    style
  );
  return (
    <div style={layoutStyle} className={className}>
      {children}
    </div>
  );
};

const Wrapper = ({ className, style, children }) => {
  const wrapperStyle = Object.assign({ flex: '1 0 auto' }, style);
  return (
    <div style={wrapperStyle} className={className}>
      {children}
    </div>
  );
};

const Loader = () => (
  <Layout>
    <Wrapper>
      <Content>
        <div className="u-margin-top">
          <div className="col-md-12">
            <Spinner size="large" />
          </div>
        </div>
      </Content>
    </Wrapper>
  </Layout>
);

const Footer = ({ style }) => {
  const mode = useAppModus();

  if (mode !== 'web') return null;

  const footerStyle = Object.assign({ flexShrink: '0' }, style);

  return (
    <div style={footerStyle} className="no-print">
      <AppFooter />
    </div>
  );
};

const Header = HeaderWrapper;

export { Header, Notifications, Content, Footer, Layout, Wrapper, Loader };
