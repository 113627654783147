import React, { useEffect, useState, useRef } from 'react';
import { Spinner } from '@a-ui/react';
import cookie from 'cookie';

import { ScrollTo } from 'components/Helpers';
import { AppointmentViewMapper } from 'mappers/Appointment';
import CreateAppointmentSeo from 'components/Seo/CreateAppointmentSeo';
import { Gtm } from 'components/Gtm';
import { getEnvironment } from 'helpers/environment';

import { Steps } from './Steps';
import { Header, Content } from '../Layout';

const FormSteps = ({
  mode,
  step = 0,
  loading = false,
  appointmentType,
  submitAppointment,
  product,
  appointment = {}
}) => {
  let [steps, setSteps] = useState(Steps);
  const [current, setCurrent] = useState(step);
  const [stepsData, setStepsData] = useState(AppointmentViewMapper(appointment.data));
  const [cookiePreferences, setCookiePreferences] = useState({
    necessaryAccepted: false,
    analyticsAccepted: false,
    marketingAccepted: false,
  });

  const prevCookiePreferences = useRef(cookiePreferences);
  

  useEffect(() => {
    // Filter out dynamic extra fields step when they arent configured for this product.
    if (!product.formConfig.extraFields || product.formConfig.extraFields.length === 0) {
      steps = steps.filter((step) => {
        return step.key !== 'extraInfo';
      });
    }

    setSteps(steps);
  }, []);

  useEffect(() => {
    const cookies = cookie.parse(document.cookie);
    const cookiePreferencesEnv = cookies[`cookiepreferences_${getEnvironment()}`];

    if (cookiePreferencesEnv) {
      const parsedPreferences = JSON.parse(cookiePreferencesEnv);

      setCookiePreferences({
        necessaryAccepted: parsedPreferences.some(
          (obj) => obj?.category === 'Noodzakelijk' && obj.accepted
        ),
        analyticsAccepted: parsedPreferences.some(
          (obj) =>
            obj?.category === 'analytics' ||
            obj?.category === 'Analytisch' ||
            obj?.category === 'Analytics'
        ),
        marketingAccepted: parsedPreferences.some(
          (obj) => obj?.category === 'Marketing' && obj.accepted
        ),
      });
    }
  }, [getEnvironment()]);

  useEffect(() => {
    if (current === 0) {
      const { necessaryAccepted, analyticsAccepted, marketingAccepted } = cookiePreferences;

      Gtm.push('page-impression', {
        url: window.location.href,
        path: window.location.pathname,
        paginanaam: 'Afspraak maken | Antwerpen.be'
      });

      if (
        necessaryAccepted !== prevCookiePreferences.current.necessaryAccepted ||
        analyticsAccepted !== prevCookiePreferences.current.analyticsAccepted ||
        marketingAccepted !== prevCookiePreferences.current.marketingAccepted
      ) {
        Gtm.updateConsent(necessaryAccepted, analyticsAccepted, marketingAccepted);
        prevCookiePreferences.current = cookiePreferences;
      }
    }

    Gtm.push('toolstep-impression', {
      appnaam: 'afspraken',
      toolnaam: 'afspraken',
      stapnaam: `formulier stap ${current + 1}`,
    });
  }, [current])

  const previous = () => {
    setCurrent(current - 1);
    ScrollTo('top');
  };

  const next = () => {
    setCurrent(current + 1);
    ScrollTo('top');
  };

  const onChange = (index, values) => {
    setStepsData((stepdata) => ({
      ...stepdata,
      [index]: values
    }));
  };

  const onSubmit = () => {
    submitAppointment(stepsData);
  };

  const renderStepComponent = () => {
    const index = current;
    const StepComponent = steps[index].component;

    return (
      <StepComponent
        product={product}
        previous={() => previous(index)}
        index={index}
        mode={mode}
        next={() => next(index)}
        onSubmit={() => onSubmit()}
        data={stepsData}
        stepData={stepsData[steps[index].key]}
        steps={steps}
        appointment={appointment}
        appointmentType={appointmentType}
        onChange={onChange}
      />
    );
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <CreateAppointmentSeo product={product} />
      <Header mode={mode} />
      <Content mode={mode}>
        <h2 className="u-margin-top">Afspraak maken</h2>
        {renderStepComponent()}
      </Content>
    </React.Fragment>
  );
};

export default FormSteps;
