import { Button } from '@a-ui/react';
import styled from 'styled-components';

const FormNavigationContainer = styled.div`
  display: flex;
  gap: 0.7rem;
  flex-direction: column; /* Stack buttons vertically */

  @media (min-width: 768px) {
    flex-direction: row; /* Revert to horizontal layout on larger screens */
  }

  .btn-previous {
    width: 8rem;
    display: inline-block;
    margin-right: 1rem;
  }

  .btn-next {
    width: 8rem;
    display: inline-block;
  }

  .btn-submit {
    width: 8rem;
    display: inline-block;
  }
`;

const FormNavigation = ({ current, previous, next, onSubmit, valid, loading = false, steps = [] }) => {
  return (
    <FormNavigationContainer className="u-margin-top">
      {current > 0 && (
        <Button
          htmlType="button"
          onClick={previous}
          disabled={loading}
          emphasis="medium"
        >
          {(current === steps.length - 1) ? 'Wijzig afspraak' : 'Vorige'}
        </Button>
      )}
      {current < steps.length - 1 && (
        <Button
          htmlType="button"
          onClick={next}
          {...(loading && {
            addOn: {
              placement: 'right',
              type: 'spinner'
            }
          })}
          disabled={!valid || loading}
        >
          Volgende
        </Button>
      )}
      {current === steps.length - 1 && (
        <Button
          htmlType="button"
          theme="success"
          onClick={onSubmit}
          {...(loading && {
            addOn: {
              placement: 'right',
              type: 'spinner'
            }
          })}
          disabled={!valid || loading}
        >
          Bevestig afspraak
        </Button>
      )}

    </FormNavigationContainer>
  );
};

export default FormNavigation;
