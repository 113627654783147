import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Calendar, Label } from '@a-ui/react';
import { animateScroll as scroll } from 'react-scroll';

import FormNavigation from '../FormNavigation';
import { ScrollTo } from 'components/Helpers';
import { getAvailableDays } from 'actions/AvailableDays';

const DatePickerStep = ({
  product = {},
  data,
  onChange,
  index,
  mode,
  stepData,
  previous,
  next,
  steps
}) => {
  const dispatch = useDispatch();
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  let [error, setError] = useState(null);

  useEffect(() => {
    ScrollTo('top');

    setSelectedDate(stepData);
    dispatch(getAvailableDays(product.id, data.duration.amount, data.locations)).then(({ payload }) => {
      setDates(payload.dates.map(dateString => {
        const [day, month, year] = dateString.split('/');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      }));
    })
  }, []);

  const getUnavailableDays = dates => {
    const startDate = new Date(dates[0]);
    const endDate = new Date(dates[dates.length - 1]);
    const missingDays = [];

    for (let currentDate = new Date(startDate); currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
      const currentDateISO = currentDate.toISOString().split('T')[0];

      if (!dates.includes(currentDateISO)) {
        missingDays.push(currentDateISO);
      }
    }

    return missingDays;
  }

  const getUnavailableTo = dates => {
    if (dates.length === 0) return;
    const firstDate = dates[0];

    // Parse the date string of the first element in the array
    const parsedFirstDate = new Date(firstDate.split('/').reverse().join('-'));

    // Subtract one day from the parsed date
    const dayBeforeFirstDate = new Date(parsedFirstDate);
    dayBeforeFirstDate.setDate(parsedFirstDate.getDate() - 1);

    // Format the result into 'yyyy-MM-dd' date string format
    const formattedDayBeforeFirstDate = dayBeforeFirstDate.toISOString().split('T')[0];

    return formattedDayBeforeFirstDate;
  };

  const getUnavailableFrom = dates => {
    if (dates.length === 0) return;
    // Get the last date from the array
    const lastDate = dates[dates.length - 1];

    // Parse the date string of the last element in the array
    const parsedLastDate = new Date(lastDate.split('/').reverse().join('-'));

    // Add one day to the parsed date
    const dayAfterLastDate = new Date(parsedLastDate);
    dayAfterLastDate.setDate(parsedLastDate.getDate() + 1);

    // Format the result into 'yyyy-MM-dd' date string format
    const formattedDayAfterLastDate = dayAfterLastDate.toISOString().split('T')[0];

    return formattedDayAfterLastDate;
  };

  const onValidate = () => {
    if (selectedDate) {
      return true;
    } else {
      setError({
        title: 'Selecteer een datum',
        description: 'Selecteer een datum om verder te gaan.'
      });
      scroll.scrollToTop();
    }
  };

  const goToNextStep = () => {
    if (onValidate()) {
      onChange('date', selectedDate);
      next();
    }
  };

  const renderTitle = () => {
    if (mode === 'kiosk') return null;

    return (
      <div className="u-margin-top u-margin-bottom">
        <h3>Selecteer een datum</h3>
        <p className="u-margin-top">Geen datum die voor jou past? Ga dan terug om andere locaties te kiezen.</p>
      </div>
    );
  };

  return (
    <>
      {mode !== 'kiosk' &&
        <div className="u-margin-top u-margin-bottom title-appointment"><Label
          type="primary"
        >{product.name}
        </Label>
        </div>
      }
      {renderTitle()}
      {error && (
        <Alert theme="danger" inline title={{ label: error.title }}>
          {error.description}
        </Alert>
      )}

      <div className="u-margin-top u-margin-bottom-xl">
        <Calendar
          value={selectedDate}
          unavailable={getUnavailableDays(dates)}
          unavailableFrom={dates.length === 0 ? '1970-01-01' : getUnavailableFrom(dates)}
          unavailableTo={getUnavailableTo(dates)}
          onChange={(date) => setSelectedDate(date)}
        />
      </div>

      <FormNavigation current={index} valid={true} previous={previous} next={goToNextStep} steps={steps} />
    </>
  );
};

export default DatePickerStep;
