function defaultProps(event, props) {
  switch (event) {
    case 'page-impression':
      return {
        ...props,
        paginatype: 'undefined',
        artikelnaam: 'undefined',
        organisatie: 'undefined'
      };
    default:
      return { ...props };
  }
}

function push(event, props) {
  window.dataLayer = window.dataLayer || [];
  const tag = defaultProps(event, props);

  window.dataLayer.push({
    event,
    ...tag
  });
}

function setConsent(storage, userData, personalization, analytics) {
  push('consent', {
    'ad_storage': storage,
    'ad_user_data': userData,
    'ad_personalization': personalization,
    'analytics_storage': analytics,
  });
}
function updateConsent(necessaryCookies, analyticalCookies, marketingCookies) {
  push('consentUpdate', {
    'necessary_cookies': necessaryCookies,
    'analytical_cookies': analyticalCookies,
    'marketing_cookies': marketingCookies,
  });
}

export const Gtm = {
  push,
  setConsent,
  updateConsent,
};
