import { getAppointmentIdBySecureLink } from 'api/Appointments';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from '@a-ui/react';
import Error from 'components/Errors/Error';

const AppointmentDispatcher = () => {
  const [id, setId] = useState('');
  const [error, setError] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const navigate = useNavigate();
  const { secureLink } = useParams();

  useEffect(() => {
    getAppointmentIdBySecureLink(secureLink)
      .then((response) => {
        setId(response.id);
      })
      .catch((e) => {
        if (e.status === 404) {
          setNotFound(true);
        }
        setError(true);
      });
  }, []);
  if (id) {
    navigate(`/afspraak/${id}`);
  }
  if (error) {
    return (
      <Error
        title={notFound ? 'Afspraak niet gevonden' : 'Afspraak'}
        fromUrl={'/'}
        theme="danger"
        button={{
          text: 'Probeer opnieuw',
          url: `/Update/${secureLink}`
        }}
        error={{
          title: 'Er ging iets mis...',
          description: notFound
            ? 'Deze afspraak werd niet gevonden. Controleer of de link correct is.'
            : 'Er is een technisch probleem, onze excuses. Probeer het later opnieuw.'
        }}
      />
    );
  }
  return (
    <div className="afs-p-loading">
      <Spinner />
    </div>
  );
};

export default AppointmentDispatcher;
