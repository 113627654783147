export const findScript = (options, target = document) => {
	if (!target) {
		return;
	}

	const scriptTags = document.querySelectorAll('script');
	const {
		src,
		attr,
		strict = true,
	} = options;

	let originScript;

	for (const scriptTag of scriptTags) {
		if (src) {
			if ((strict && scriptTag.src === src) || (!strict && scriptTag.src.includes(src))) {
				originScript = scriptTag;

				break;
			}
		}

		if (attr) {
			if (scriptTag.attributes[attr]) {
				originScript = scriptTag;

				break;
			}
		}
	}

	if (!originScript && target !== document.head) {
		return findScript(options, document.head);
	}

	return originScript;
};

export const addScript = (attrs, content, identifier, target = document) => {
	if (!target || findScript(attrs, target)) {
		return;
	}

	const scriptTag = document.createElement('script');

	const {
		src,
		type,
		noModule,
		crossorigin,
		defer,
	} = attrs;

	if (src) {
		scriptTag.src = src;
	}

	if (type) {
		scriptTag.type = type;
	}

	if (noModule) {
		scriptTag.noModule = true;
	}

	if (crossorigin) {
		scriptTag.crossorigin = crossorigin;
	}
	if (defer) {
		scriptTag.defer = defer;
	}

	if (content) {
		const scriptContent = document.createTextNode(content);

		scriptTag.append(scriptContent);
	}

	scriptTag.setAttribute[`data-${identifier}`] = '';

	target.append(scriptTag);
};

export const findStyle = (options, target) => {
	const styleTags = target.querySelectorAll('link');
	const {
		href,
		strict = true,
	} = options;
	let styleTag;

	for (const [i, styleTag_] of styleTags.entries()) {
		if (href) {
			if ((strict && styleTag_.href === href) || (!strict && scriptTags[i].href.includes(href))) {
				styleTag = styleTag_;

				break;
			}
		}
	}

	return styleTag;
};

const getStyleTag = (attrs) => {
	if (attrs.tagName && attrs.tagName.toLowerCase() === 'style') {
		return attrs;
	}

	const styleTag = document.createElement('link');
	const {
		href,
		rel = 'stylesheet',
		type,
		as = 'style',
	} = attrs;

	if (href) {
		styleTag.setAttribute('href', href);
	}

	if (rel) {
		styleTag.setAttribute('rel', rel);
	}

	if (type) {
		styleTag.setAttribute('type', type);
	}

	if (as) {
		styleTag.setAttribute('as', as);
	}

	return styleTag;
};

export const addStyle = (attrs, target, identifier) => {
	target = target || (document ? document.head : null);

	if (!target || findStyle(attrs, target)) {
		return;
	}

	const styleTag = getStyleTag(attrs);

	styleTag.setAttribute(`data-${identifier}`, ''); // eslint-disable-line unicorn/prefer-dataset

	target.append(styleTag);
};

export const poll = (check, cb) => {
	const timer = setTimeout(() => {
		clearInterval(checker);
	}, 30000);

	const checker = setInterval(() => {
		if (!!check()) {
			clearInterval(checker);
			clearTimeout(timer);
			cb();
		}
	}, 100);
};
