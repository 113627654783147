import { createRoot } from 'react-dom/client';
import { applyPolyfills, defineCustomElements } from '@a-ui/web-components/loader';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import * as serviceWorker from './serviceWorker';
import store from './store/configureStore';
import { UserProvider } from './contexts/User';
import App from './App';

import './styles/main.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <UserProvider>
        <App />
      </UserProvider>
    </Provider>
  </BrowserRouter>,
);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
