import { Button } from '@a-ui/react';

const StartAppointment = ({
  selectedProduct,
  onSubmit
}) => {

  return (
    <div className="row u-margin-top">
      <div className="col-xs-12 col-md-6">
        <Button
          disabled={selectedProduct === ''}
          onClick={onSubmit}
        >
          Verdergaan
        </Button>
      </div>
    </div>
  );
};

export default StartAppointment;
