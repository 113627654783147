export const getWidget = (widgetName, cb) => {
	const w = window;

	poll(
		() => {
			if (!w.React || !w.ReactDOM || !w.LayoutRenderer) {
				return;
			}

			for(const lib in w.LayoutRenderer) {
				if (!!w.LayoutRenderer[lib][widgetName]) {
					return w.LayoutRenderer[lib][widgetName];
				}
			}
		},
		(widget) => {
			cb(w.React, w.ReactDOM, widget);
		},
	);
};

const poll = (check, cb) => {
	const timer = setTimeout(() => {
		clearInterval(checker);
	}, 10000);

	const checker = setInterval(() => {
		const result = check();

		if (result !== undefined) {
			clearInterval(checker);
			clearTimeout(timer);
			cb(result);
		}
	}, 10);
};
