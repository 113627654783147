import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { nlBE } from 'date-fns/locale';
import { Icon } from '@a-ui/react';

const DEFAULT_TIMEZONE = 'Europe/Brussels';

const OverviewContainer = styled.div`
  & > .item {
    margin-bottom: 1rem;
		display: flex;
    line-height: 1.2;

    label {
      font-size: 18px;
      min-width: 30px;
      text-align: center;
      padding-right: 1rem;
    }

    .title {
      display: inline;
    }

    .icon {
      width: 18px;
    }

    .description {
      .item {
        display: block;
      }
  }
`;

const renderExtraFields = (product, fields) => {
  const output = product.formConfig.extraFields.map((field) => {
    const name = field.name;
    let value = fields[name];

    if (!value || value === '') {
      return {
        label: field.label,
        value: '-'
      };
    }

    if (field.type === 'location') {
      if (value.formattedAddress) {
        value = `${value.formattedAddress}`;
      } else {
        value = `${value.label}`;
      }
    }

    return {
      label: field.label,
      value
    };
  });

  return output;
};

const ProductDetail = ({
  product,
  timeSlot,
  duration,
  amount,
  location = {},
  user = {},
  extraFields = [],
}) => (
  <OverviewContainer>
    <div key={'product'} className="item u-margin-top-xs">
      <label className="item__label u-text-light">
        <span className={'item__icon'}>
          <Icon name="info" />
        </span>
      </label>
      <span>
        <span className="title">Reden van je afspraak</span>
        <br />
        <span className="description">
          <b>{product.name}</b>
        </span>
        <br />
      </span>
    </div>

    <div key={'date'} className="item">
      <label className="item__label u-text-light">
        <span className={'item__icon'}>
          <Icon name="calendar" />
        </span>
      </label>
      <span>
        <span className="title">Datum en tijd</span>
        <br />
        <span className="description">
          <b>{format(parseISO(timeSlot), 'EEEE d MMMM yyyy', { locale: nlBE })}</b> om{' '}
          <b>{formatInTimeZone(parseISO(timeSlot), DEFAULT_TIMEZONE, 'HH:mm \'uur\'')}</b>
        </span>
        <br />
      </span>
    </div>

    <div key={'time'} className="item">
      <label className="item__label u-text-light">
        <span className={'item__icon'}><Icon name="watch-time" /></span>
      </label>
      <span>
        <span className="title">Duur van je afspraak</span>
        <br />
        <span className="description">
          <b>{duration || 10} minuten ({`${amount} ${amount === 1 ? 'exemplaar' : 'exemplaren'}`})</b>
        </span>
      </span>
    </div>

    <div key={'location'} className="item">
      <label className="item__label u-text-light">
        <span className={'item__icon'}><Icon name="pin" /></span>
      </label>
      <span>
        <span className="title">Locatie van je afspraak</span>
        <br />
        <span className="description">
          <b>{location.alias || location.name}</b>
          <br />
          <span style={{ fontSize: '0.9rem' }}>{location.address.street} {location.address.streetNumber}, {location.address.zipCode} {location.address.city}</span>
          {location.extraInfo && <span style={{ color: '#f18a00' }}><Icon name="ai-alert-circle" /> {location.extraInfo}</span>}
        </span>
      </span>
    </div>

    <div key={'user'} className="item">
      <label className="item__label u-text-light">
        <span className={'item__icon'}><Icon name="single-neutral" /></span>
      </label>
      <span>
        <span className="title">Wie verwachten we op de afspraak?</span>
        <br />
        <span className="description">
          {user.firstName && user.lastName && (
            <span className="item" style={{ fontSize: '0.9rem' }}>
              <b>
                {user.firstName} {user.lastName}
              </b>
            </span>
          )}
          {user.birthDate && (
            <span className="item" style={{ fontSize: '0.9rem' }}>{format(parseISO( user.birthDate), 'dd/MM/yyyy')}</span>
          )}
          {user.phone && <span className="item" style={{ fontSize: '0.9rem' }}>{user.phone}</span>}
          {user.email && <span className="item" style={{ fontSize: '0.9rem' }}>{user.email}</span>}
          {user.postalCode && <span className="item" style={{ fontSize: '0.9rem' }}>{user.postalCode}</span>}
        </span>
      </span>
    </div>

    {(product.formConfig.extraFields && product.formConfig.extraFields.length > 0) &&
      <div key={'info'} className="item">
        <label className="item__label">
          <Icon name="ai-alert-circle" />
        </label>
        <span>
          <span className="title">Extra info</span>
          <br />
          <span className="description">
            {renderExtraFields(product, extraFields).map((field) => {
              return (
                <span className="item" key={`extra_field_${field.label}`}>
                  <b>
                    {field.label}: {field.value}
                  </b>
                </span>
              );
            })}
          </span>
        </span>
      </div>
    }
  </OverviewContainer>
);

export default ProductDetail;