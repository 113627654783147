import { propOr } from 'ramda';

import { StencilWrapper } from '../StencilWrapper/StencilWrapper';
import { addStyle } from '../../utils/dom';
import { useRenderContext } from '../../hooks/useRenderContext';

export const StencilWidget = ({
	id,
	widgetAttributes,
	widgetState,
	widgetEvents,
	widgetChildren,
	...props
}) => {
	const { injector, rootElement } = useRenderContext();

	if (Array.isArray(propOr(null, 'stylesheetUrls', widgetState))) {
		widgetState.stylesheetUrls.forEach((style) => {
			addStyle(style, rootElement, id);
		});
	}

	return (
		<StencilWrapper
			name={id}
			injector={injector}
			eventListeners={widgetEvents}
			{...widgetAttributes}
			{...widgetChildren}
			{...widgetState}
			{...props}
		>
			<WidgetTag />
		</StencilWrapper>
	);
}
