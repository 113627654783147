const notificationSettings = {
  wrapperId: 'astad-notification-wrapper',
  types: [
    {
      type: 'info',
      icon: 'ai-info',
      classList: 'info'
    },
    {
      type: 'error',
      icon: 'ai-alert-circle',
      classList: 'error'
    },
    {
      type: 'warning',
      icon: 'ai-alert-circle',
      classList: 'warning'
    },
    {
      type: 'success',
      icon: 'ai-check-1',
      classList: 'success'
    },
    {
      type: 'notification',
      icon: 'ai-alarm-bell',
      classList: 'notification'
    }
  ]
};

export { notificationSettings };
