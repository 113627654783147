import React, { useEffect, useState, useRef } from 'react';
import cookie from 'cookie';

import { Gtm } from 'components/Gtm';
import { getEnvironment } from 'helpers/environment';

const GTM_ID = 'GTM-N5LHXQ';

const GoogleTagManager = () => {
  const [cookiePreferences, setCookiePreferences] = useState({
    necessaryAccepted: false,
    analyticsAccepted: false,
    marketingAccepted: false,
  });

  const prevCookiePreferences = useRef(cookiePreferences);

  useEffect(() => {
    const cookies = cookie.parse(document.cookie);
    const cookiePreferencesEnv = cookies[`cookiepreferences_${getEnvironment()}`];

    if (cookiePreferencesEnv) {
      const parsedPreferences = JSON.parse(cookiePreferencesEnv);

      setCookiePreferences({
        necessaryAccepted: parsedPreferences.some(
          (obj) => obj?.category === 'Noodzakelijk' && obj.accepted
        ),
        analyticsAccepted: parsedPreferences.some(
          (obj) =>
            (obj?.category === 'analytics' ||
            obj?.category === 'Analytisch' ||
            obj?.category === 'Analytics') &&
            obj?.accepted
        ),
        marketingAccepted: parsedPreferences.some(
          (obj) => obj?.category === 'Marketing' && obj.accepted
        ),
      });
    }
  }, [getEnvironment()]);

  useEffect(() => {
    const { necessaryAccepted, analyticsAccepted, marketingAccepted } = cookiePreferences;
    const script = document.createElement('script');
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${GTM_ID}');
    `;
    if (analyticsAccepted) {
      document.head.appendChild(script);
    }

    if (
      necessaryAccepted !== prevCookiePreferences.current.necessaryAccepted ||
      analyticsAccepted !== prevCookiePreferences.current.analyticsAccepted ||
      marketingAccepted !== prevCookiePreferences.current.marketingAccepted
    ) {
      Gtm.setConsent(
        marketingAccepted ? 'granted' : 'denied',
        marketingAccepted ? 'granted' : 'denied',
        marketingAccepted ? 'granted' : 'denied',
        analyticsAccepted ? 'granted' : 'denied'
      );

      Gtm.updateConsent(necessaryAccepted, analyticsAccepted, marketingAccepted);

      prevCookiePreferences.current = cookiePreferences;
    }

  }, [cookiePreferences]);

  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>
    </noscript>
  );
};

export default GoogleTagManager;