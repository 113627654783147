import { TextField as NativeTextField } from '@a-ui/react';

import { createError } from 'components/Helpers';

const NumberField = ({ field, value, onChange, errors }) => (
  <>
    <NativeTextField
      label={field.label}
      name={field.name}
      value={value}
      type={'text'}
      {...(field.validators.find((validator) => validator.type === 'positive') && { min: 0 })}
      onKeyDown={(e) => {
        if (e.key.length === 1 && /\D/.test(e.key)) {
          e.preventDefault();
        }
      }}
      placeholder={field.placeholder}
      onChange={(e) => {
        const input = e.target.value;
        const numbers = /^[-+]?[0-9]*$/;

        if (input.match(numbers)) {
          onChange(field.name, input);
        }
      }}
      required={field.validators.find((validator) => validator.type === 'required')}
      description={
        errors[field.name] && createError(errors[field.name].message)
      }
      state={errors[field.name] && 'error'}
    />
  </>
);

export default NumberField;
