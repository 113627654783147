import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import TextField from './Fields/TextField';
import NumberField from './Fields/Number';
import Attachment from './Fields/Attachment';
import Location from './Fields/Location';

import './ExtraFields.scss';

const SingleField = ({ field, errors = {}, value, onChange }) => {
  switch (field.type) {
    case 'text':
      return <TextField field={field} value={value} onChange={onChange} errors={errors} />;
    case 'number':
      return <NumberField field={field} value={value} onChange={onChange} errors={errors} />;
    case 'location':
      return <Location field={field} onChange={onChange} errors={errors} value={value} />;
    case 'attachment':
      return <Attachment />;
    default:
      return null;
  }
};

const ExtraFields = ({ fields = [], defaultValues = {}, setValid, submitting, setSubmitting, setValues }) => {
  const validationSchema = () => {
    let validation = {};

    fields.map((field) => {
      if (field.type == 'text') {
        validation[field.name] = yup
          .string()
          .trim()
          .max(50, 'Maximum 50 karakters toegelaten.')
        ;
      }

      if (field.validators) {
        validation[field.name] = yup
          .string()
          .trim()
        ;

        field.validators.forEach((validator) => {
          if (validator.type === 'required') {
            validation[field.name] = yup
              .string()
              .trim()
              .required(validator.message || 'Dit is een verplicht veld.')
              .concat(field.type === 'text' ? yup.string().max(50, 'Maximum 50 karakters toegelaten.') : null)
            ;
          }

          else if (validator.type === 'positive') {
            validation[field.name] = yup
              .string()
              .trim()
              .concat(field.validators.some((validator) => validator.type === 'required') ? yup.string().required('Dit is een verplicht veld.') : null)
              .test(
                'Is positive?',
                'De waarde moet positief zijn.',
                function (value) {
                  if (!value || value?.length === 0) return true;

                  return Number(value) >= 0;
                }
              )
            ;
          } else {
            validation[field.name] = yup
              .string()
              .trim()
            ;
          }
        });
      }
    });

    return yup.object().shape(validation);
  };

  const {
    setValue,
    trigger,
    control,
    watch,
    getValues,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onBlur',
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema())
  });

  useEffect(() => {
    if (submitting) {
      trigger();
      setSubmitting(false);
    }
  }, [submitting]);

  useEffect(() => {
    if (isValid) {
      setValues(getValues());
    }
    setValid(isValid);
  }, [isValid]);

  useEffect(() => {
    const subscription = watch(() => setValues(getValues()));
    return () => subscription.unsubscribe();
  }, [watch]);

  const output = fields.map((field, index) => {
    return (
      <div className="u-margin-top" key={index}>
        <Controller
          key={index}
          name={field.name}
          control={control}
          defaultValue={defaultValues[field.name]}
          render={() => (
            <SingleField
              key={index}
              field={field}
              value={getValues(field.name)}
              onChange={(key, value) => {
                setValue(key, value);
                trigger(key);
              }}
              errors={errors}
            />
          )}
        />
      </div>
    );
  });

  return (
    <div>
      <form>{output}</form>
    </div>
  );
};

export default ExtraFields;
