import { combineReducers } from 'redux';
import productsReducer from './Products';
import productReducer from './Product';
import locationsReducer from './Locations';
import availableDaysReducer from './AvailableDays';
import availableTimeslotsReducer from './AvailableTimeSlots';
import { AppointmentReducer, AppointmentsReducer } from './Appointments';
import durationReducer from './Duration';

const rootReducer = combineReducers({
  productsReducer,
  productReducer,
  locationsReducer,
  availableDaysReducer,
  availableTimeslotsReducer,
  AppointmentReducer,
  AppointmentsReducer,
  durationReducer
});

export default rootReducer;
