import { Footer as AppFooter } from '@a-ui/react';

const Footer = () => (
  <AppFooter items={[
    {
      label: '© stad Antwerpen',
    },
    {
      label: 'Privacy en gebruik',
      href: '#',
    },
    {
      label: 'Toegankelijkheid',
      href: '#',
    },
    {
      label: 'Cookies',
      onClick: () => (document.querySelector('aui-cookie-consent')).openPreferences = true
    }
  ]} />
);

export default Footer;
