import {
  FETCH_AVAILABLE_TIME_SLOTS_BEGIN,
  FETCH_AVAILABLE_TIME_SLOTS_SUCCESS,
  FETCH_AVAILABLE_TIME_SLOTS_FAILURE,
} from '../actions/types';

const initialState = {
  slots: [],
  loading: false,
  error: null,
};

export default function availableTimeSlotsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case FETCH_AVAILABLE_TIME_SLOTS_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case FETCH_AVAILABLE_TIME_SLOTS_SUCCESS:
      return {
        ...state,
        loading: false,
        slots: action.payload.slots,
      };
    case FETCH_AVAILABLE_TIME_SLOTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        slots: [],
      };
    default:
      return state;
  }
}
