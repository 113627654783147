import { transformEvents } from '../utils/events';

import { useRenderContext } from './useRenderContext';

export const useEvents = ({ component, events }) => {
	const { dispatcher, widgetRegistry } = useRenderContext();

	const { events: defaultEvents } = widgetRegistry.getWidget(component) || {};

	return transformEvents(dispatcher, {
		...defaultEvents,
		...events,
	});
};
