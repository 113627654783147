export const checkState = (layoutWidgets) => {
	if (!layoutWidgets) {
		return [];
	}

	return layoutWidgets.map((layoutWidget) => {
		const hasState = (layoutWidget || {})["state"] || null;

		// If hasState is false provide some default state
		// - Setup astad-content & astad-context datastores by ref
		// - Setup widgetContent & isMultiple state for each widget
		// return updated layoutWidget
		if (!hasState) {
			const stateCheckedLayoutWidgets = {
				...layoutWidget,
				datastores: {},
				state: {},
			};

			// Setup astad-content datastore
			stateCheckedLayoutWidgets.datastores["astad-content"] = {
				$ref: "astad-content",
			};

			// Setup astad-context datastore
			stateCheckedLayoutWidgets.datastores["astad-context"] = {
				$ref: "astad-context",
			};

			if (layoutWidget.id) {
				// Setup widgetContent state
				stateCheckedLayoutWidgets.state.widgetContent = {
					datastore: "astad-content",
					selector: ["result", layoutWidget.id, "value"],
				};

				// Setup widgetContent state
				stateCheckedLayoutWidgets.state.isMultiple = {
					datastore: "astad-content",
					selector: ["result", layoutWidget.id, "isMultiple"],
				};
			}

			return stateCheckedLayoutWidgets;
		}

		return layoutWidget;
	});
};
