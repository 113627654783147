import { clone, mergeDeepRight, pick } from "ramda";

export class ReduxDevTools {
	static identifier = "LayoutRendererReduxDevTools";

	config = {};
	devTools = null;
	devToolsEnabled = false;
	state = {};

	constructor(options = {}) {
		this.devToolsEnabled = false;
		if (typeof window !== "undefined") {
			this.devToolsEnabled = !!(window && window.__REDUX_DEVTOOLS_EXTENSION__);
		}
		this.config = this.getConfig(options);
	}

	init(initialState = {}) {
		if (!this.devToolsEnabled || typeof window === "undefined") {
			console.info(
				"Redux dev tools not detected. Please go to https://github.com/zalmoxisus/redux-devtools-extension for instructions."
			);
			return;
		}

		this.devTools = window.__REDUX_DEVTOOLS_EXTENSION__.connect(this.config);
		this.state = mergeDeepRight(clone(this.state), initialState);

		this.devTools.init(this.state);
	}

	send(action, newState) {
		if (!this.devToolsEnabled || !this.devTools) {
			return;
		}

		this.state = mergeDeepRight(clone(this.state), newState);

		this.devTools.send(action, this.state);
	}

	getConfig(options) {
		return pick(
			[
				"name",
				"actionCreators",
				"latency",
				"maxAge",
				"trace",
				"traceLimit",
				"serialize",
				"actionSanitizer",
				"stateSanitizer",
				"actionsBlacklist",
				"actionsWhiteList",
				"predicate",
				"shouldRecordChanges",
				"pauseActionType",
				"autoPause",
				"shouldStartLocked",
				"shouldHotReload",
				"shouldCatchErrors",
				"features",
			],
			options
		);
	}
}
