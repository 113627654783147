import {
  createNewAppointmentBegin,
  createNewAppointmentSuccess,
  createNewAppointmentFailure,
  updateAppointmentBegin,
  updateAppointmentSuccess,
  updateAppointmentFailure,
  fetchAppointmentBegin,
  fetchAppointmentSuccess,
  fetchAppointmentFailure,
  deleteAppointmentBegin,
  deleteAppointmentSuccess,
  deleteAppointmentFailure,
  fetchAppointmentsBegin,
  fetchAppointmentsSuccess,
  fetchAppointmentsFailure
} from './types';
import {
  createAppointment,
  updateAppointment,
  fetchAppointment,
  deleteAppointment,
  fetchAppointments
} from '../api/Appointments';
import { AppointmentSubmitMapper } from '../mappers/Appointment';

export const newAppointment = (data, product) => (dispatch) => {
  dispatch(createNewAppointmentBegin());
  return createAppointment(AppointmentSubmitMapper(data, product))
    .then((body) => dispatch(createNewAppointmentSuccess(body)))
    .catch((error) => dispatch(createNewAppointmentFailure(error)));
};

export const editAppointment = (appointment, data, email = '') => {
  return (dispatch) => {
    dispatch(updateAppointmentBegin());
    return updateAppointment(appointment.id, AppointmentSubmitMapper(data, appointment.product), email)
      .then((body) => dispatch(updateAppointmentSuccess(body)))
      .catch((error) => dispatch(updateAppointmentFailure(error)));
  };
};

export const getAppointment = (id, email = '') => {
  return (dispatch) => {
    dispatch(fetchAppointmentBegin());
    return fetchAppointment(id, email)
      .then((body) => dispatch(fetchAppointmentSuccess(body)))
      .catch((error) => dispatch(fetchAppointmentFailure(error)));
  };
};

export const removeAppointment = (id, email = '') => {
  return (dispatch) => {
    dispatch(deleteAppointmentBegin());
    return deleteAppointment(id, email)
      .then((body) => dispatch(deleteAppointmentSuccess(body)))
      .catch((error) => dispatch(deleteAppointmentFailure(error)));
  };
};

export const getAppointments = () => {
  return (dispatch) => {
    dispatch(fetchAppointmentsBegin());
    return fetchAppointments()
      .then((body) => dispatch(fetchAppointmentsSuccess(body)))
      .catch((error) => dispatch(fetchAppointmentsFailure(error)));
  };
};
