import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Checkbox, CheckboxList, Icon, Label } from '@a-ui/react';
import { animateScroll as scroll } from 'react-scroll';

import FormNavigation from '../FormNavigation';
import { ScrollTo } from 'components/Helpers';
//import UpdateAppointmentBox from 'components/FormSteps/UpdateAppointmentBox';
import { getLocations } from 'actions/Locations';

const LocationsStep = ({
  // appointment,
  product = {},
  // data,
  onChange,
  index,
  mode,
  stepData,
  previous,
  next,
  steps
}) => {
  const dispatch = useDispatch();
  let [chosenLocations, setChosenLocations] = useState([]);
  let [error, setError] = useState(null);

  const { availableLocations } = useSelector((state) => ({
    availableLocations: {
      loading: state.locationsReducer.loading,
      error: state.locationsReducer.error,
      locations: state.locationsReducer.locations
    },
  }));

  useEffect(() => {
    ScrollTo('top');

    dispatch(getLocations(product.id));
    setChosenLocations(stepData || []);
  }, []);

  const onValidate = () => {
    if (chosenLocations.length > 0) {
      return true;
    } else {
      setError({
        title: 'Selecteer een locatie',
        description: 'Selecteer minstens 1 locatie om verder te gaan.'
      });
      scroll.scrollToTop();
    }
  };

  const goToNextStep = () => {
    if (onValidate()) {
      onChange('locations', chosenLocations);
      next();
    }
  };

  const renderTitle = () => {
    if (mode === 'kiosk') return null;

    return (
      <div className="u-margin-top u-margin-bottom">
        <h3>Selecteer 1 of meer locaties</h3>
        <p className="u-margin-top">Tip: wil je snel een afspraak? Selecteer dan een regioloket als dat kan voor jouw vraag.
        Regioloketten zijn vaker open dan stadsloketten.
        </p>
      </div>
    );
  };

  const renderLocationContent = location => {
    return (
      `<b>${location.name}</b><br/>
      ${location.address.street} ${location.address.streetNumber}, ${location.address.zipCode} ${location.address.city}<br/>`);
  }

  const onItemClick = location => {
    if (chosenLocations.some((chosenLocations) => chosenLocations.id === location.id)) {
      // If the value is already in the array, remove it
      setChosenLocations(chosenLocations.filter((item) => item.id !== location.id));
    } else {
      // If the value is not in the array, add it
      setChosenLocations([...chosenLocations, location]);
    }
  };

  // Override of the react components ListItemBox because it can't render Icon component
  const ListItemBox = (props) => (
    <li className="a-checkbox-list__item">
      <div className="a-input a-checkbox-list__checkbox">
        <Checkbox
          {...props}
          label={
            <>
              {props.label}
              {props.extraInfo && <span style={{ color: '#f18a00' }}><Icon name="ai-alert-circle" /> {props.extraInfo}</span>}
            </>
          } />
      </div>
    </li>
  );

  return (
    <>
      {mode !== 'kiosk' &&
        <div className="u-margin-top u-margin-bottom title-appointment"><Label
          type="primary"
        >{product.name}
        </Label>
        </div>
      }
      {renderTitle()}
      {error && (
        <Alert theme="danger" inline title={{ label: error.title }}>
          {error.description}
        </Alert>
      )}

      <div className="u-margin-top u-margin-bottom-xl">
        <CheckboxList
          flushed
        >
          {availableLocations.locations.length > 0 && availableLocations.locations.map((location => (
            <ListItemBox
              checked={chosenLocations.some((chosenLocations) => chosenLocations.id === location.id)}
              onChange={() => onItemClick(location)}
              key={`location-${location.id}`}
              id={`location-${location.name}`}
              name={location.id}
              label={<div dangerouslySetInnerHTML={{ __html: renderLocationContent(location) }} />}
            >
            </ListItemBox>
          )))}
        </CheckboxList>
      </div>

      <FormNavigation current={index} valid={true} previous={previous} next={goToNextStep} steps={steps} />
    </>
  );
};

export default LocationsStep;
