import { StencilWrapper } from '@layout-renderer/core';

import { getEnvironment } from 'helpers/environment';
import CookieConsentConfig from './CookieConsentConfig';

const TRANSLATIONS = {
  'buttonAccept': 'Alle cookies aanvaarden',
  'buttonReject': 'Optionele cookies weigeren',
  'buttonSetup': 'Voorkeuren instellen',
  'buttonBack': 'Terug',
  'textCookieTypes': 'Soorten cookies',
  'textCookiePolicy': 'Lees onze cookieverklaring',
  'buttonSaveSettings': 'Voorkeuren opslaan',
  'tableHeaderCookie': 'Cookie',
  'tableHeaderType': 'Type',
  'tableHeaderDuration': 'Duur',
  'tableHeaderDescription': 'Omschrijving',
  'textAlwaysOn': 'Altijd ingeschakeld',
  'textOn': 'Ingeschakeld',
  'textOff': 'Uitgeschakeld',
};

const CookieConsent = () => {
  const onCookieSave = () => {
    window.location.reload(false);
  };

  return (
    <StencilWrapper
      preferencesSaved={onCookieSave}
    >
        <aui-cookie-consent
          {...(process.env.NEXT_PUBLIC_ENV === 'production' && {
            domain: '.antwerpen.be',
          })}
          environment={getEnvironment()}
          translations={JSON.stringify(TRANSLATIONS)}
          config={JSON.stringify(CookieConsentConfig)}
          preferencesSaved={onCookieSave}
        />
    </StencilWrapper>
  );
};

export default CookieConsent;