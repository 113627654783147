import React from 'react';
import { flatten, isNil, groupBy, pathOr, propOr } from 'ramda';

import { Widget } from '../components/Widget/Widget';
import { getChildrenContent } from './get-children-content';

// Transform slots to their respective widgets
export const transformChildren = (content, stores, children) => {
	if (Array.isArray(children)) {
		return children.map((child, i) => {
			const key = `${child.id}-${i}`;
			const attributes = pathOr(null, ['attributes'], child);
			const slotName = pathOr(null, ['slot'], attributes);
			const area = pathOr(null, ['area'], attributes);

			// if child specifies its content directly, use that, otherwise take from passed down content
			const widgetContent = propOr(getChildrenContent(content, child.id), 'widgetContent', child);

			return (
				<Widget
					key={key}
					src={child}
					{...widgetContent}
					stores={stores}
					slot={!!slotName ? slotName : ''}
					area={!!area ? area : ''}
				/>
			);
		});
	}

	return [];
};

const getChildKey = (key) => {
	// Can't use children as part of the key.
	// Because using children as a prop on a Stencil component causes build conflict
	// With existing children prop in Stencil
	return key ? `${key}Slots` : 'slots';
};

// Get the child components grouped by slot name
export const getChildren = (content, stores, ...widgetsList) => {
	// flatten widgets json
	const children = flatten(widgetsList.filter(s => !isNil(s)));
	// transform json to components
	const transformedChildren = transformChildren(content, stores, children);
	// group by slot name
	const groupedChildren = groupBy(pathOr('', ['props', 'slot']), transformedChildren);

	return Object.entries(groupedChildren).reduce((acc, [key, values]) => ({
		...acc,
		[getChildKey(key)]: values
	}), {});
}
