import { useEffect } from 'react';
import { Alert } from '@a-ui/react';

import { ScrollTo } from 'components/Helpers';

const AppointmentDeleted = () => {
  useEffect(() => {
    ScrollTo('top');
  }, []);

  return (
    <div className="u-margin-top">
      <h3>Afspraak geannuleerd</h3>
      <>
        <div className="u-margin-top u-margin-bottom">
          <Alert
            inline
            theme="success"
            title={{
              label: 'Je hebt je afspraak geannuleerd.'
            }}
            titleId="aui-alert-title"
          >
            Je kan een nieuwe afspraak maken als je wil.
          </Alert>
        </div>
      </>
    </div>
  );
};

export default AppointmentDeleted;
