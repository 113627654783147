import React from "react";

import { WidgetType } from "../../enums/widget-type.enum";
import { withDataStores } from "../../hoc/withDatastores";
import { withMultiple } from "../../hoc/withMultiple";
import { withDynamicLayout } from "../../hoc/withDynamicLayout";
import { useDataStores } from "../../hooks/useDataStores";
import { useEvents } from "../../hooks/useEvents";
import { useRenderContext } from "../../hooks/useRenderContext";
import { getChildren } from "../../utils/get-children";
import { getDynamicChildren } from "../../utils/get-children-dynamic";

const WidgetComponent = ({
	src,
	stores /* inherited datastores, augmented from src.datastores */,
	widgetContent /* deprecated */,
	...props
}) => {
	const { id, component, attributes, children, generateChildren } = src;
	const { widgetRegistry } = useRenderContext();
	const widgetState = useDataStores(src, stores);
	const widgetEvents = useEvents(src);

	const {
		component: WidgetFC = null,
		tag: WidgetTag = null,
		attributes: defaultAttributes = null,
		// default children from widget registration
		children: defaultChildren = null,
		widgetType = WidgetType.tag,
	} = widgetRegistry.getWidget(component) || {};

	const widgetAttributes = {
		...(defaultAttributes || {}),
		...(attributes || {}),
	};

	const widgetProps = {
		...props,
		...(widgetContent || {}) /* deprecated */,
		stores,
	};

	// children (json) generated from data (via generateChildren config)
	const dynamicChildren = getDynamicChildren(
		{
			...widgetProps, // passed down widgetContent
			...widgetAttributes, // set from layout
			...widgetState, // queried from stores
		},
		generateChildren
	);

	// all children (components) mapped to slots
	const widgetChildren = getChildren(
		{
			...widgetProps, // content can be passed down
			...widgetState,
		},
		stores,
		defaultChildren,
		children,
		dynamicChildren
	);

	if (widgetType === WidgetType.tag && WidgetTag) {
		return (
			<StencilWidget
				name={id}
				{...widgetAttributes}
				{...widgetState}
				{...widgetEvents}
				{...widgetChildren}
				{...widgetProps}
			/>
		);
	}

	if (widgetType === WidgetType.component && WidgetFC) {
		return (
			<WidgetFC
				name={id}
				{...widgetAttributes}
				{...widgetState}
				{...widgetEvents}
				{...widgetChildren}
				{...widgetProps}
			/>
		);
	}

	return null;
};

// execution order during render = outside in (dynamic layout first)
export const Widget = withDynamicLayout(
	withDataStores(withMultiple(WidgetComponent))
);
