import { fromEvent } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export const handleHashChange = (hash, el) => {
	if (!hash || !el || !el.shadowRoot) {
		return;
	}

	const target = el.shadowRoot.querySelector(hash);

	if (target) {
		target.scrollIntoView();
	}
};

export const popstate = () =>
	fromEvent(window, 'popstate')
		.pipe(
			map(() => window.location.hash),
			distinctUntilChanged(),
		)
;
