(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("rxjs"), require("rxjs/operators"), require("ramda"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "rxjs", "rxjs/operators", "ramda", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["LayoutRenderer"] = factory(require("react"), require("rxjs"), require("rxjs/operators"), require("ramda"), require("react-dom"));
	else
		root["LayoutRenderer"] = root["LayoutRenderer"] || {}, root["LayoutRenderer"]["Core"] = factory(root["react"], root["rxjs"], root["rxjs/operators"], root["ramda"], root["react-dom"]);
})((function () {
				if (typeof self !== 'undefined') {
					return self;
				} else if (typeof window !== 'undefined') {
					return window;
				} else if (typeof global !== 'undefined') {
					return global;
				} else {
					return Function('return this')();
				}
			})(), (__WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_rxjs__, __WEBPACK_EXTERNAL_MODULE_rxjs_operators__, __WEBPACK_EXTERNAL_MODULE_ramda__, __WEBPACK_EXTERNAL_MODULE_react_dom__) => {
return 