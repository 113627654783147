import { Reducer } from "./Reducer";
import { Storage } from "./Storage";

import { transformActions } from "../utils/action";
// import { ReduxDevTools } from '../utils/devtools';

export class SimpleDataStore {
	static identifier = "SimpleDataStore";

	repository;
	storage;
	reducer;
	devtools;
	debug;

	actions;
	config;

	constructor(config, injector, context) {
		// Store config
		// -> usable for creating new instances
		this.config = config;

		this.initialization(config, injector, context);
	}

	initialization(config, injector, { debug = false } = {}) {
		// Set config.name as identifier
		// ==================================================
		this.identifier = config.name;

		this.debug = debug;

		// Transform actions to be validated and executed
		// ==================================================
		this.actions = config.actions
			? transformActions(
				config.actions.map((action) => {
					return { ...action, url: config.url };
				})
			  )
			: config.actions;

		// storage: store results
		// ==================================================
		this.storage = new Storage(config.storage, config.name);

		// reducer: determine new state
		// ==================================================
		this.reducer = new Reducer(config.actions);

		// DevTools
		// ==================================================
		// this.devtools = injector.get(ReduxDevTools);

		// if (this.debug && this.devtools) {
		// 	this.devtools.init({
		// 		[this.identifier]: this.storage.snapshot(),
		// 	});
		// }

		// Execute actions inside initial actions
		// ==================================================
		this.executeInitialActions(config.initialActions);
	}

	async handleAction({ type, payload }) {
		// Determine the action result
		const actionResult = await this.determineActionResult(type, payload);
		// Determine the new state
		const newState = this.reducer.reduce(this.storage.getState(), {
			type,
			result: actionResult,
		});
		// Set new state
		this.storage.setState(newState);

		if (this.debug && this.devtools) {
			this.devtools.send(
				{ type, result: actionResult },
				{
					[this.config.name]: newState,
				}
			);
		}
	}

	executeInitialActions(initialActions) {
		// Check if initialActions is array
		if (Array.isArray(initialActions)) {
			// Call handleAction for each action
			initialActions.forEach((action) => {
				this.handleAction(action);
			});
		}
	}

	dispatch(action) {
		this.handleAction(action);
	}

	select(dataPath) {
		return this.storage.select(dataPath);
	}

	snapshot() {
		return this.storage.snapshot();
	}

	async determineActionResult(type, payload) {
		// Get function based on given type that matches current list of actions
		const transformPayload = this.actions[type];

		return transformPayload ? transformPayload(payload) : payload;
	}
}
