import { Helmet } from 'react-helmet';

import { getEnv } from 'hooks/config';

const FavIcon = () => {
  let env = getEnv();
  if (env === 'local') env = 'dev';

  return (
    <Helmet>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={process.env.PUBLIC_URL + `/assets/images/favicon/${env}/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={process.env.PUBLIC_URL + `/assets/images/favicon/${env}/favicon-32x32.png`}
      />

      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={process.env.PUBLIC_URL + `/assets/images/favicon/${env}/favicon-16x16.png`}
      />

      <link rel="manifest" href={process.env.PUBLIC_URL + `/assets/images/favicon/${env}/manifest.json`} />

      <link
        rel="mask-icon"
        href={process.env.PUBLIC_URL + `/assets/images/favicon/${env}/safari-pinned-tab.svg`}
        color="#cf0039"
      />
    </Helmet>
  );
};

export default FavIcon;
