import { Helmet } from 'react-helmet';

const CreateAppointmentSeo = ({ product }) => {
  return (
    <Helmet>
      {/* General tags */}
      <title>{product.name} | Antwerpen.be</title>
      <meta name="description" content={product.description || `Maak uw afspraak voor ${product.name}`} />
      <meta name="robots" content="noindex" />
      <meta name="googlebot" content="noindex" />

      {/* OpenGraph tags */}
      <meta name="og:title" content={`${product.name} | Antwerpen.be`} />
      <meta name="og:description" content={product.description || `Maak uw afspraak voor ${product.name}`} />
      <meta name="og:url" content={`/${product.slug}`} />
      <meta name="og:type" content="website" />
      <meta name="og:site_name" content="A-stad" />
      <meta name="og:image" content="https://www.antwerpen.be/assets/aOS/gfx/gui/a-logo.svg" />

      {/* Twitter Card tags */}
      <meta name="twitter:title" content={`${product.name} | Antwerpen.be`} />
      <meta name="twitter:description" content={product.description || `Maak uw afspraak voor ${product.name}`} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@Stad_Antwerpen" />
      <meta name="twitter:image" content="https://www.antwerpen.be/assets/aOS/gfx/gui/a-logo.svg" />
    </Helmet>
  );
};

export default CreateAppointmentSeo;
