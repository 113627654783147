const DisplayedAttachment = ({ attachment }) => {
  console.log(attachment);
  return (
    <li>
      <span className="ai" aria-hidden="true">
        <svg>
          <use href="https://cdn.antwerpen.be/core_branding_scss/5.0.2/assets/images/ai.svg#ai-common-file-empty" />
        </svg>
      </span>
      {/* <span className="m-upload__filename">my-doc.pdf</span> */}
      <button className="m-upload__delete a-button-transparent a-button--default a-button--small has-icon">
        <span className="ai" aria-hidden="true">
          <svg>
            <use href="https://cdn.antwerpen.be/core_branding_scss/5.0.2/assets/images/ai.svg#ai-remove" />
          </svg>
        </span>
        <span className="u-screen-reader-only">Sluiten</span>
      </button>
    </li>
  );
};
const Attachment = ({ files = [] }) => {
  const onFileChange = (e) => {
    console.log(e.target.files[0]);
    const selectedFile = e.target.files[0];

    const formData = new FormData();
    formData.append('File', selectedFile);

    fetch('https://api-gw-p.antwerpen.be/astad/assets/v1', {
      method: 'POST',
      body: formData
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <>
      <div className="m-upload">
        <div className="m-upload__inner">
          <div className="m-upload__dropzone">
            <input id="upload-3" type="file" multiple="multiple" className="m-upload__input" onChange={onFileChange} />
            <div className="m-upload__content">
              <label htmlFor="upload-3" className="m-upload__message u-margin-bottom">
                Sleep een bestand hierheen of klik om te bladeren
              </label>
              {/* <p className="m-upload__uploads u-text-bold u-margin-bottom-xs">my-doc.pdf and my-pic.jpg</p> */}
            </div>
          </div>
        </div>
        <small className="m-upload__description">Maximale bestandsgrootte: 4MB</small>
        {files.length > 0 && (
          <ul className="m-upload__files">
            {files.map((file, index) => (
              <DisplayedAttachment key={`attachment_${index}`} attachment={file} />
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default Attachment;
