/**
 * Inject the layout provider based on the provided configuration
 * @param {{ type: string; factory: any; }[]} providerTypes 
 * @param {LayoutProviderConfig} provider 
 * @param {*} injector 
 */
export const setupLayoutProvider = (providerTypes, providerConfig, injector) => {
	if (!Array.isArray(providerTypes) || typeof providerConfig !== 'object') return null;

	const providerType = providerTypes.find((definition) => providerConfig.type === definition.type);
	if (providerType) {
		const provider = new providerType.factory(providerConfig);
		const token = injector.getInjectionToken('LayoutProvider', provider);
		injector.set(token);
		return provider;
	} else {
		console.warn(`setupLayoutProvider: unknown provider type ${providerConfig.type}`);
	}
	return null;
}
