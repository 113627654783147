import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Label, Select, Spinner } from '@a-ui/react';
import { animateScroll as scroll } from 'react-scroll';
import { format, parseISO } from 'date-fns';
import { nlBE } from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';

import { ScrollTo } from 'components/Helpers';
import { getAvailableTimeSlots } from 'actions/AvailableTimeSlots';
import FormNavigation from '../FormNavigation';

const DEFAULT_TIMEZONE = 'Europe/Brussels';

const TimeSlotStep = ({
  // appointment,
  product = {},
  data,
  onChange,
  index,
  mode,
  stepData,
  previous,
  next,
  steps
}) => {
  const dispatch = useDispatch();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({});
  const { availableTimeSlots } = useSelector((state) => ({
    availableTimeSlots: {
      loading: state.availableTimeslotsReducer.loading,
      error: state.availableTimeslotsReducer.error,
      slots: state.availableTimeslotsReducer.slots
    }
  }));
  let [error, setError] = useState(null);
  const dateSlot = data.date || {};

  useEffect(() => {
    ScrollTo('top');

    dispatch(getAvailableTimeSlots(product.id, data.date, data.duration.amount, data.locations));
    setSelectedTimeSlot(stepData);
  }, []);

  const onValidate = () => {
    if (selectedTimeSlot.slot) {
      return true;
    } else {
      setError({
        title: 'Selecteer een tijdstip',
        description: 'Selecteer een tijdstip om verder te gaan.'
      });
      scroll.scrollToTop();
    }
  };

  const goToNextStep = () => {
    if (onValidate()) {
      onChange('timeslot', selectedTimeSlot);
      next();
    }
  };

  const renderTitle = () => {
    if (mode === 'kiosk') return null;

    return (
      <div className="u-margin-top u-margin-bottom">
        <h3>Selecteer een tijdstip</h3>
        <p className="u-margin-top">Zie je geen tijdstip dat voor jou past? Ga terug en selecteer een andere datum of locatie.</p>
      </div>
    );
  };

  const onSelectTimeSlot = (slot, location) => {
    setSelectedTimeSlot({
      slot,
      location
    })
  };

  const formatTimeSlots = slots => {
    return slots.flatMap((timeSlotPerHour) =>
      timeSlotPerHour.timeSlots
        .filter((timeslot) => timeslot.available)
        .map((timeslot) => ({
          key: timeslot.startTime,
          value: timeslot.startTime,
          label: formatInTimeZone(parseISO(timeslot.startTime), DEFAULT_TIMEZONE, 'HH.mm \'uur\'')
        }))
    );
  };

  return (
    <>
      <div className="u-margin-top u-margin-bottom title-appointment"><Label
        type="primary"
      ><b>{product.name}</b> op <b>{format(parseISO(dateSlot), 'EEEE dd/MM/yyyy', { locale: nlBE })}</b>.
      </Label>
      </div>
      {renderTitle()}
      {error && (
        <Alert theme="danger" inline title={{ label: error.title }}>
          {error.description}
        </Alert>
      )}
      {availableTimeSlots.loading && <Spinner />}
      <div className="u-margin-top u-margin-bottom-xl">
        {availableTimeSlots.slots.map((slot => (
          <div className="u-margin-top" key={`${slot.location.name}`}>
            <h5 className="u-margin-bottom">{slot.location.name}</h5>
            <Select
              id={`select-timeslots-${slot.location.name}`}
              label={'Selecteer een tijdstip'}
              name={`select-timeslots-${slot.location.name}`}
              required={false}
              disabled={formatTimeSlots(slot.timeSlotsPerHour || []).length === 0}
              placeholder={formatTimeSlots(slot.timeSlotsPerHour || []).length > 0 ? 'Selecteer een tijdstip' : 'Geen tijdstippen beschikbaar'}
              options={formatTimeSlots(slot.timeSlotsPerHour || [])}
              value={(selectedTimeSlot?.location?.id === slot.location.id ? selectedTimeSlot.slot : null)}
              onChange={e => onSelectTimeSlot(e.target.value, slot.location)}
            />
          </div>
        )))}
      </div>

      <FormNavigation current={index} valid={true} previous={previous} next={goToNextStep} steps={steps} />
    </>
  );
};

export default TimeSlotStep;
