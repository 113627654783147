import { pathOr } from 'ramda';

import { validateSchema } from './validate-schema';

export const getActionConfig = (actions, { type }) => {
	// based on the action type get the reducer saved action that is associated
	return actions.find((action) => action.type === type);
}

// Emitted payloads will usually be objects with key values
// However they need to be associated with an arg type
// in order to be acceptable for scheme validation
export const transformPayload = (action, payload = {}) => {
	const args = pathOr(null, ['args'], action);
	const argsProperties = pathOr(null, ['properties'], args);

	if (!args && !argsProperties) {
		return null;
	}

	return Object.keys(action.args.properties).reduce((args, arg) => {
		if (action.args.properties[arg]) {
			args[arg] = Object.keys(payload).reduce((acc, curr) => {
				if (action.args.properties[arg].properties[curr]) {
					acc[curr] = payload[curr];
					return acc;
				}
			}, {});
		}
		return args;
	}, {});
}

// Parse actions and transform them to a function
// If args is present it returns a function with payload -> call to API and store value
// If action has no method it returns null -> store key value and no call to API
// The payload is transformed so that it can be validated together with the action
export const transformActions = (actions) => {
	if (!actions) {
		return {};
	}

	return actions.reduce((acc, curr) => {
		if (!curr.method) {
			acc[curr.type] = null;
			return acc;
		}

		acc[curr.type] = (payload) => validateSchema(curr, transformPayload(curr, payload));
		return acc;
	}, {});
}
