import React, { Component } from "react";
import { omit } from "ramda";

export class StencilWrapper extends Component {
	wc = React.createRef();
	propsNames = [];
	wcReady = false;
	wcReadyInterval;

	componentDidMount() {
		this.waitForWebComponent()
			.then(() => {
				const {
					children,
					eventListeners,
					componentDidMountCallback,
					...props
				} = this.props;
				this.addEventListeners(eventListeners);
				this.updateProps(props);
				if (componentDidMountCallback) {
					componentDidMountCallback(this.wc);
				}
				return;
			})
			.catch((error) => console.log(error));
	}

	componentDidUpdate() {
		if (this.wcReady) {
			this.updateProps(
				omit(
					["children", "eventListeners", "componentDidMountCallback"],
					this.props,
				),
			);
		}
	}

	componentWillUnmount() {
		const { eventListeners } = this.props;
		this.removeEventListeners(eventListeners);
		if (this.wcReadyInterval) {
			clearInterval(this.wcReadyInterval);
		}
	}

	waitForWebComponent = () =>
		new Promise((resolve) => {
			if (this.wcReady) {
				return resolve();
			}

			this.wcReadyInterval = setInterval(async () => {
				if (this.wc && this.wc.current) {
					clearInterval(this.wcReadyInterval);

					if (typeof this.wc.current.componentOnReady === "function") {
						await this.wc.current.componentOnReady();
					}

					this.wcReady = true;

					return resolve();
				}
			}, 10);
		});

	updateProps = (props) => {
		if (this.wcReady) {
			Object.keys(props).forEach((propName) => {
				this.wc.current[propName] = this.props[propName];
			});
		}
	};

	addEventListeners = (eventListeners = {}) => {
		if (this.wcReady) {
			Object.entries(eventListeners).forEach(([name, value]) => {
				this.wc.current.addEventListener(name, value);
			});
		}
	};

	removeEventListeners = (eventListeners = {}) => {
		if (this.wcReady) {
			Object.entries(eventListeners).forEach(([name, value]) => {
				this.wc.current.removeEventListener(name, value);
			});
		}
	};

	render() {
		const { children } = this.props;

		return React.cloneElement(children, { ref: this.wc });
	}
}
