import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash.get';
import isEmpty from 'lodash/isEmpty';

import useAppModus from 'hooks/useAppModus';
import { UserContext } from 'contexts/User';
import {
  FETCH_APPOINTMENT_FAILURE,
  UPDATE_APPOINTMENT_FAILURE,
  UPDATE_APPOINTMENT_SUCCESS
} from '../actions/types';
import Error from 'components/Errors/Error';
import AppointmentSecurity from 'components/ViewAppointment/AppointmentSecurity';
import FormSteps from 'components/Wizard/FormSteps';
import { getAppointment, editAppointment } from 'actions/Appointments';

const EditAppointment = () => {
  const mode = useAppModus();
  const session = useContext(UserContext);

  const [step] = useState(0);
  const [email, setEmail] = useState('');
  let { id } = useParams();
  const navigate = useNavigate();
  const { state, search } = useLocation();
  const dispatch = useDispatch();

  const { appointment } = useSelector((state) => ({
    appointment: {
      success: state.AppointmentReducer.success,
      type: state.AppointmentReducer.type,
      loading: state.AppointmentReducer.loading,
      error: state.AppointmentReducer.error,
      data: state.AppointmentReducer.appointment
    }
  }));

  useEffect(() => {
    dispatch(getAppointment(id, get(state, 'email')));
    setEmail(get(state, 'email'));
  }, []);

  const submitEmail = (email) => {
    dispatch(getAppointment(id, email));
    setEmail(email);
  };

  if (appointment.error && appointment.type === UPDATE_APPOINTMENT_FAILURE) {
    const status = appointment.error.status;
    switch (status) {
      case 409:
        return <Error
          title="Afspraak maken"
          // fromUrl={`/${product.data.slug}`}
          theme="danger"
          button={{
            text: 'Maak een nieuwe afspraak',
            url: `/${appointment.data.product.data.slug}`,
          }}
          error={{
            title: 'Tijdstip niet meer beschikbaar',
            description: 'Het tijdstip dat je selecteerde voor deze locatie is niet meer beschikbaar.'
          }} />;
      default:
        return <Error
          title="Afspraak maken"
          // fromUrl={`/${product.data.slug}`}
          theme="danger"
          button={{
            text: 'Probeer opnieuw',
            url: `/${appointment.data.product.data.slug}`,
          }}
          error={{
            title: 'Er ging iets mis...',
            description: 'Er is een technisch probleem, onze excuses. Probeer het later opnieuw.'
          }} />;
    }
  }

  if (appointment.error && appointment.type === FETCH_APPOINTMENT_FAILURE) {
    return (
      <AppointmentSecurity
        session={session}
        email={email}
        status={appointment.error.status}
        submitEmail={submitEmail}
      />
    );
  }

  if (appointment.type === UPDATE_APPOINTMENT_SUCCESS) {
    navigate(`/afspraak/${appointment.data.id}${search}`, {
      state: {
        search: '',
        edited: true,
        appointment: appointment.data,
        email: appointment.data.form.email || ''
      }
    });
  }

  if (isEmpty(appointment.data)) {
    return null;
  }

  return (
    <FormSteps
      mode={mode}
      step={step}
      appointment={appointment}
      appointmentType="edit"
      product={appointment.data.product}
      session={session}
      submitAppointment={(data) => dispatch(editAppointment(appointment.data, data, email))}
    />
  );
};

export default EditAppointment;
