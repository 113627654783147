import { propOr, omit } from 'ramda';
import React from 'react';

import { isObject } from '../utils/is-object';
import { Widget } from '../components/Widget/Widget';

/**
 * return multiple widgets for multiple values
 * @deprecated use generateChildren instead
 * @param {*} widgetState
 * The state to fetch items from, array or object with `value` array property
 * @param {*} src
 * The widget configuration to use for every widget
 * @returns array of `<Widget>` components
 */
export const getMultiple =  (widgetState, src) => {
	const widgetStateItems = Array.isArray(widgetState) ? widgetState : [];
	const items = propOr(widgetStateItems, 'value', widgetState);

	return items.map((item, i) => {
		const key = `${src.id}-${i}`;
		const itemState = isObject(item) ? item : { value: item };

		return <Widget
			key={key}
			src={{
				...omit(['state', 'datastores'], src),
				attributes: {
					...src.attributes,
					isMultiple: false,
				},
			}}
			{...itemState}
		/>;
	});
};
