import { useContext } from 'react';
import { Alert, Button } from '@a-ui/react';

import { UserContext } from 'contexts/User';
import useAppModus from 'hooks/useAppModus';
import { Content, Header } from '../Layout';

const Error = ({ title, error = {}, theme, button = {}, fromUrl, inline = false }) => {
  const session = useContext(UserContext);
  const mode = useAppModus();

  return (
    <>
      <Header session={session} />
      <Content>
        {(mode === 'web' && !inline) &&
          <div className="row">
            <Button
              onClick={() => window.location.href = fromUrl}
              addOn={{
                type: 'icon',
                iconProps: {
                  name: 'remove'
                }
              }}
              emphasis="low">
            </Button>
          </div>
        }
        <h2>{title}</h2>
        <div className="u-margin-top">
          <Alert
            inline={inline}
            theme={theme || 'warning'}
            title={{
              label: error.title
            }}
            titleId="aui-alert-title"
          >
            {error.description}
          </Alert>
        </div>
        {(button.text && mode === 'web' ) && <div className="u-margin-top">
          <Button
            onClick={() => window.location.href = button.url}
          >
            {button.text}
          </Button>
          </div>
        }
      </Content>
    </>
  );
};

export default Error;
