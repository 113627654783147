import { Route, Routes, useSearchParams } from 'react-router-dom';

import { Notifications, Footer, Layout, Wrapper } from 'components/Layout';
import CookieConsent from 'components/CookieConsent/CookieConsent';
import { UserProvider } from 'contexts/User';
import Error from 'components/Errors/Error';
import GoogleTagManager from 'components/GoogleTagManager/GoogleTagManager';
import FavIcon from 'components/FavIcon';
import { ScrollToTop } from 'components/Helpers';
import SelectAppointment from 'containers/SelectAppointment';
import CreateAppointment from 'containers/CreateAppointment';
import EditAppointment from 'containers/EditAppointment';
import ViewAppointment from 'containers/ViewAppointment';
import AppointmentDispatcher from 'containers/AppointmentDispatcher';
import Redirect from 'components/Redirect';
import { getEnv } from 'hooks/config';
import useConfig from 'hooks/useConfig';

import './App.scss';

const App = () => {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode') || 'web';
  const { ANTWERPEN_BE_URL } = useConfig();
  const env = getEnv().toLowerCase();

  return (
    <div className="App">
      {mode === 'web' && <CookieConsent />}
      <FavIcon />
      <Notifications />
      <GoogleTagManager />
      <Layout mode={mode}>
        <Wrapper>
          <UserProvider>
            <ScrollToTop>
              <Routes>
                <Route exact path="/" element={env === 'prod' ? <Redirect url={ANTWERPEN_BE_URL} /> : <SelectAppointment />} />
                <Route exact path="/" element={<SelectAppointment />} />
                <Route path="/afspraak/:id/wijzigen" element={<EditAppointment />} />
                <Route exact path="/afspraak/:id" element={<ViewAppointment />} />
                <Route exact path="/:slug" element={<CreateAppointment />} />
                <Route exact path="/Update/:secureLink" element={<AppointmentDispatcher />} />
                <Route exact path="/Remove/:secureLink" element={<AppointmentDispatcher />} />
                <Route path="*" component={<Error error={'Deze pagina kon niet gevonden worden'} />} />
              </Routes>
            </ScrollToTop>
          </UserProvider>
        </Wrapper>
        <Footer mode={mode} />
      </Layout>
    </div>
  );
};

export default App;
