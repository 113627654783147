import { useEffect, useState } from 'react';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { getWindowDimensions } from '../utils/get-dimensions';

export const useDimensions = () => {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		const obs = fromEvent(window, 'resize')
			.pipe(debounceTime(100))
			.subscribe(() => setWindowDimensions(getWindowDimensions()));

		return () => obs.unsubscribe();
	}, []);

	return windowDimensions;
};
