import { BehaviorSubject } from "rxjs";

import { Injector } from "./Injector";

export class Dispatcher {
	static identifier = "Dispatcher";
	static Dependencies = [Injector];

	injector;

	events = new BehaviorSubject(null);

	constructor(injector) {
		this.injector = injector;
	}

	dispatch(eventType, args, payload) {
		const { datastore: identifier, type } = args || {};
		// Events that need to be handled in the store
		if (eventType === "store") {
			const datastore = this.injector.get(identifier);

			if (!datastore) {
				throw new Error(`Could not find datastore "${identifier}"!`);
			}

			datastore.dispatch({
				type,
				payload,
			});
		}
		// Events that need to be emitted outside the layout renderer
		return this.events.next({ type, event: payload, payload });
	}
}
