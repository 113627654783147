import ErrorHandler from './ErrorHandler';

export function fetchAvailableTimeSlots(productId, day, amount, locationIds = '') {
  return fetch(
    `/api/products/${productId}/available-days/${day}/available-time-slots?amount=${amount}&locations=${locationIds}`,
    { method: 'GET' }
  )
    .then(ErrorHandler)
    .then((response) => response.json());
}
