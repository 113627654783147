import { format } from 'date-fns';

import { fetchAvailableTimeSlotsBegin, fetchAvailableTimeSlotsSuccess, fetchAvailableTimeSlotsFailure } from './types';
import { fetchAvailableTimeSlots } from '../api/AvailableTimeSlots';

export const getAvailableTimeSlots =
  (productId, day, amount = 1, chosenLocations = []) =>
    (dispatch) => {
      const locationIds = getSelectedLocationsIds(chosenLocations);
      dispatch(fetchAvailableTimeSlotsBegin());

      const formattedDate = format(new Date(day), 'yyyy-MM-dd');
      return fetchAvailableTimeSlots(productId, formattedDate, amount, locationIds).then(
        (data) => dispatch(fetchAvailableTimeSlotsSuccess(data)),
        (err) => dispatch(fetchAvailableTimeSlotsFailure(err))
      );
    };

const getSelectedLocationsIds = (chosenLocations) => {
  const displayedLocations = [];
  chosenLocations.forEach((location) => {
    if (location) {
      displayedLocations.push(location.id);
    }
  });

  return displayedLocations.join(',');
};
