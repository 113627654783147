import React, { useEffect } from "react";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { LayoutWidget } from "../LayoutWidget/LayoutWidget";

import { checkState } from "../../utils/check-state";
import { handleHashChange, popstate } from "../../utils/hash";
import { noop } from "../../utils/noop";
import { RenderProvider } from "../../context/render-context";
import { useRenderContext } from "../../hooks/useRenderContext";
import { defaultDefinedDatastores } from "../../utils/datastores";

const Widgets = ({ layout, onEvents = noop("onEvents") }) => {
	const { injector, dispatcher, rootElement } = useRenderContext();
	if (!injector) {
		return null;
	}
	// Subscribe on events
	useEffect(() => {
		const destroyed$ = new Subject();
		dispatcher.events
			.pipe(
				filter((x) => !!x),
				takeUntil(destroyed$)
			)
			.subscribe(onEvents);
		popstate()
			.pipe(takeUntil(destroyed$))
			.subscribe((hash) => handleHashChange(hash, rootElement));
		if (window && window.location.hash) {
			setTimeout(
				() => handleHashChange(window.location.hash, rootElement),
				1000
			);
		}
		return () => {
			destroyed$.next(true);
			destroyed$.complete();
		};
	}, []);

	return <LayoutWidget layout={layout} />;
};

export const LayoutRendererProvider = ({
	id = "layout-renderer-provider",
	debug = false,
	data,
	layout = {},
	stylesheetUrls = [],
	datastores: datastoreTypes,
	layoutProviderTypes,
	layoutProvider,
	widgets,
	widgetFallback,
	onEvents,
	el,
}) => {
	if (!layout || !data) {
		if (debug) {
			console.warn("LayoutRenderer: layout or data not specified");
		}
		return null;
	}

	const { datastores = null, context = { baseUrl: "" } } = layout;
	const { fields } = data;
	const layoutContext = {
		debug,
		...context,
	};

	// Add default defined data stores with layout spec defined data stores
	const layoutDataStores = defaultDefinedDatastores(
		datastores,
		context,
		fields,
		stylesheetUrls
	);

	// Check if root layout widgets have state, if not add default state for widget content
	const layoutWidgets = checkState(layout.widgets);

	return (
		<RenderProvider
			rootElement={el}
			datastoreTypes={datastoreTypes}
			datastores={layoutDataStores}
			layoutProviderTypes={layoutProviderTypes}
			layoutProvider={layoutProvider}
			widgets={widgets}
			widgetFallback={widgetFallback}
			context={layoutContext}
			debug={debug}
		>
			<Widgets
				layout={{
					...layout,
					widgets: layoutWidgets,
				}}
				onEvents={onEvents}
			/>
		</RenderProvider>
	);
};
