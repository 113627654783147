import { propOr, mergeAll } from 'ramda';

import { isObject } from './is-object';

// Transform config based events to actual events with creatEventHandler
export const transformEvents = (dispatcher, events) => {
	return events && Object.keys(events).reduce((acc, curr) => {
		acc[curr] = createEventHandler(events[curr], dispatcher);
		return acc;
	}, {})
}

// Combine events from layout spec and from widget spec
export const combineEvents = (eventsList) => {
	if (eventsList.every((e) => isObject(e))) {
		return mergeAll(eventsList);
	}

	return {};
}

// Create event handlers for the config based events
// - store -> function which receives payload and dispatches an event on the Dispatcher
// - other -> ...
export const createEventHandler = ({ type, args }, dispatcher) => {
	return (payload) => dispatcher.dispatch(type, propOr(null, 'action', args), payload);
}
