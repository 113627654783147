import { format, parse} from 'date-fns';

export const AppointmentSubmitMapper = (data, product) => {
  const dataStepDuration = data.duration || {};
  const dataStepTimeSlot = data.timeslot || {};
  const dataStepBasicInfo = data.basicInfo || {};
  const dataStepExtraInfo = data.extraInfo || {};

  const output = {
    product: product.id,
    location: dataStepTimeSlot.location.id,
    user: dataStepBasicInfo.userId,
    form: {
      firstName: dataStepBasicInfo.firstName,
      lastName: dataStepBasicInfo.lastName,
      phoneNumber: dataStepBasicInfo.phone,
      dateOfBirth: format(new Date(dataStepBasicInfo.birthDate), 'dd/MM/yyyy'),
      email: dataStepBasicInfo.email,
      postalCode: dataStepBasicInfo.postalCode,
      amount: dataStepDuration.amount || 1,
      extraFields: {
        ...dataStepExtraInfo
      }
    },
    startTime: dataStepTimeSlot.slot
  };

  product.formConfig.extraFields.forEach(function (field) {
    const name = field.name;
    output.form.extraFields[name] = dataStepExtraInfo[name] || undefined;
  });

  return output;
};

export const AppointmentViewMapper = (data) => {
  const birthDate = data?.form?.dateOfBirth;

  const output = {
    duration: {
      amount: data?.form?.amount || 1,
      duration: data?.duration
    },
    locations: [],
    date: null,
    timeslot: {},
    extraInfo: data?.form?.extraFields || {},
    basicInfo: {
      birthDate: birthDate && format(parse(birthDate, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
      email: data?.form?.email,
      firstName: data?.form?.firstName,
      lastName: data?.form?.lastName,
      phone: data?.form?.phoneNumber,
      postalCode: data?.form?.postalCode
    }
  };

  return output;
};
