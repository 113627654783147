import { isEmpty } from 'ramda';

import { SCREEN_SIZES } from '../enums/screen-sizes.enum';

export const getScreenSizes = (windowSize) => {
	const screenSizes = Object.entries(SCREEN_SIZES).filter(([, value]) => value <= windowSize);

	if (isEmpty(screenSizes)) {
		return [['xs', 480]];
	}

	return screenSizes;
};
