import { last, splitEvery } from 'ramda';

export const gridLayoutTemplateAreas = (items, columns) => {

	if (columns === 1) {
		return items.map((item, index) => `feedItem${index}`);
	}

	const templateAreas = items.map((item, index) => `feedItem${index}`);
	const transformedTemplateAreas = splitEvery(columns, templateAreas).map((areas) => areas.join(' '));

	const lastTransformedTemplateArea = last(transformedTemplateAreas);
	const filler = items.length % columns;

	if (filler > 0) {
		transformedTemplateAreas[transformedTemplateAreas.length - 1] = [
			lastTransformedTemplateArea,
			new Array(columns - filler).fill('.').join(' '),
		].join(' ');
	}

	return transformedTemplateAreas;
};
