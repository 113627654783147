import { fetchProducts } from '../api/Products';
import {
  fetchProductsBegin,
  fetchProductsSuccess,
  fetchProductsFailure,
} from './types';

export const getProducts = () => (dispatch) => {
  dispatch(fetchProductsBegin());
  return fetchProducts().then(
    data => dispatch(fetchProductsSuccess(data)),
    err => dispatch(fetchProductsFailure(err)),
  );
};
