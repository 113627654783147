/**
 * generate widget children (json config) from attributes data
 * @param {*} attributes 
 * @param {*} generateConfig { fromAttribute, withLayout, asStore, asAttribute }
 * Fetch items data from `fromAttribute` (defaults to `items`), generate `withLayout` for every item, 
 * put items data in attribute `asAttribute` if specified, 
 * or in store with name `asStore` if specified,
 * or in widgetContent is `asContent` is true or asAttribute and asStore are not specified.
 * If the item is a primitive type it is added to the store as the `value` property.
 * @returns layout JSON (array) for the children
 */
export const getDynamicChildren = (attributes, generateConfig) => {
	if (generateConfig && attributes) {
		const fromAttribute = generateConfig.fromAttribute || "items";
		const items = attributes[fromAttribute];
		const asStore = generateConfig.asStore;
		const asAttribute = generateConfig.asAttribute;
		const asContent = !!generateConfig.asContent || (!asStore && !asAttribute);
		const layout = generateConfig.withLayout;
		if (layout && items && items.length > 0) {
			return items.map((item) => {
				const attributes = { ...layout.attributes };
				const datastores = { ...layout.datastores };
				if (asAttribute) {
					attributes[asAttribute] = item;
				};
				if (asStore) {
					// wrap primitive types as a value object
					const data = ((typeof item === 'object') && !Array.isArray(item)) ? item : { value: item };
					datastores[asStore] = {
						'$config': {
							local: true,
							name: asStore,
							type: 'simple',
							storage: data
						}
					}
				}
				return {
					...layout,
					attributes,
					datastores,
					widgetContent: asContent ? item : {} 
				};
			});
		}
	}
	return [];
}
