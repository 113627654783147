import { Select } from '@a-ui/react';

const SelectProduct = ({
  products,
  selectedProduct,
  onChange
}) => {
  if (products.length < 1) return null;

  products = products.map((product) => ({
    key: product.slug,
    value: product.slug,
    label: product.name,
  }));

  return (
    <div className="row">
      <div className="col-xs-12 col-md-6 u-margin-top">
        <Select
          id={'select-products'}
          label={'Waarvoor wilt u een afspraak maken?'}
          name={'select-products'}
          required={true}
          placeholder={'Maak een keuze uit de lijst'}
          options={products}
          value={selectedProduct}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default SelectProduct;