import { fetchAvailableDays } from '../api/AvailableDays';
import { fetchAvailableDaysBegin, fetchAvailableDaysSuccess, fetchAvailableDaysFailure } from './types';

export const getAvailableDays =
  (productId, amount = 1, chosenLocations) =>
    (dispatch) => {
      const locationIds = getSelectedLocationsIds(chosenLocations);
      dispatch(fetchAvailableDaysBegin());
      return fetchAvailableDays(productId, amount, locationIds).then(
        (data) => dispatch(fetchAvailableDaysSuccess(data)),
        (err) => dispatch(fetchAvailableDaysFailure(err))
      );
    };

const getSelectedLocationsIds = (chosenLocations = []) => {
  const displayedLocations = [];

  chosenLocations.forEach((location) => {
    if (location) {
      displayedLocations.push(location.id);
    }
  });

  return displayedLocations.join(',');
};
