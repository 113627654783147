import React from 'react';
import { isObject } from '../../utils/is-object';

import { Widget } from '../Widget/Widget';

/**
 * Render a layout's widgets
 * 
 * @param {*} object containing:
 * - layout: the layout to render the widgets from
 * - content: data to pass as widgetContent to each Widget (based on widget id, only for <astad-layout-widget>)
 * - ...props: remaining properties are passed directly to each widget (only for <astad-layout-widget>) 
 * 
 * @returns the <Widget> components to render
 */
export const LayoutWidget = ({ layout, content, ...props }) => {
	const { widgets: layoutWidgets } = layout;

	return layoutWidgets.map((widget, i) => {
		const key = `${widget.id}-${i}`;
		const widgetContent = content && (content[widget.id] || content);
		const contentValue = widgetContent !== undefined ? (isObject(widgetContent) ? widgetContent : { value: widgetContent }) : {};

		return (
			<Widget
				key={key}
				src={widget}
				{...contentValue}
				isMultiple={widget.isMultiple}
				{...props}
			/>
		);
	});
};
