import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import get from 'lodash.get';
import { format, parseISO } from 'date-fns';

function formatBirthDate(date) {
  if (!date || date.length !== 8) {
    return null;
  }

  const formattedDate = format(parseISO(date), 'yyyy-MM-dd');

  return formattedDate;
}

const validationSchema = () => {
  let validation = Yup.object().shape({
    userId: Yup.string().nullable(),
    firstName: Yup.string().trim().required('Gelieve een voornaam in te vullen.'),
    lastName: Yup.string().trim().required('Gelieve een naam in te vullen.'),
    birthDate: Yup.string()
      .trim()
      .required('Gelieve een geboortedatum in te vullen.')
    ,
    email: Yup.string()
      .trim()
      .required('Gelieve een e-mailadres in te vullen.')
      .email('Gelieve een correct e-mailadres in te vullen'),
    phone: Yup.string().trim().required('Gelieve een telefoonnummer in te vullen.'),
    postalCode: Yup.string().trim().required('Gelieve een postcode in te vullen.')
  });

  return validation;
};

const initialValues = (session, stepData) => {
  const [searchParams] = useSearchParams();

  const values = {
    userId: searchParams.get('userId') || get(window, 'user.id') || get(session, 'user.id ') || stepData?.userId || undefined,
    firstName: stepData?.firstName || searchParams.get('firstName') || get(window, 'user.firstName') || get(session, 'user.firstName') || '',
    lastName: stepData?.lastName || searchParams.get('lastName') || get(window, 'user.lastName') || get(session, 'user.lastName') || '',
    email: stepData?.email || searchParams.get('email') || get(window, 'user.emailPrimary') || get(session, 'user.emailPrimary') || '',
    phone: stepData?.phone || searchParams.get('phone') || get(window, 'user.phonePrimary') || get(session, 'user.phonePrimary') || '',
    postalCode: stepData?.postalCode || '',
    birthDate:
      stepData?.birthDate ||
      searchParams.get('birthDate') ||
      formatBirthDate(get(window, 'user.birthDate')) ||
      formatBirthDate(get(session, 'user.birthDate')) ||
      ''
  };

  return values;
};

export { validationSchema, initialValues };
