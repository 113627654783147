import Ajv from 'ajv';

export const validateSchema = (action, payload) => {

	if (!payload) {
		return { ...action, args: {} };
	}

	// Options for AJV instance
	// - AllErrors -> get all errors
	// - useDefaults -> makes it possible to define defaults for properties in schemes
	const ajvOptions = {
		allErrors: true,
		useDefaults: true,
	};

	// new AJV instance with ajvOptions
	const ajv = new Ajv(ajvOptions);

	// compile action args
	const validate = ajv.compile(action.args);
	// validate the payload with the action args scheme
	const valid = validate(payload);

	// If not valid return empty object + console warning for every error
	if (!valid) {
		validate.errors.forEach((err) => console.warn(err.dataPath + ' ' + err.keyword + ' ' + err.message));
		return {};
	}

	// return action with updated args (= transformed payload)
	return {
		...action,
		args: {
			...payload
		}
	}
}
