import React, { useState, useEffect } from 'react';

import { useRenderContext } from '../hooks/useRenderContext';

export const withDynamicLayout = (Widget) => ({ src, stores, ...props }) => {
	const { injector } = useRenderContext();
	if (!injector) return null;

	const usesDynamicLayout = !src.component;
	const [dynamicLayout, setDynamicLayout] = useState();
	const [loadingError, setLoadingError] = useState();
	/** @type {AbstractLayoutProvider} */
	let layoutProvider;
	if (usesDynamicLayout) {
		layoutProvider = injector.get('LayoutProvider');
	}

	const data = { ...props, ...src };

	// Initially state fetching is still occurring, so no rules match yet because of missing props.
	// Rerun layout fetching every time the props change, until a matching layout is found.
	useEffect(() => {
		if (!usesDynamicLayout || !layoutProvider || dynamicLayout) return;

		let layoutQuery;
		if (data.layoutId) {
			layoutQuery = layoutProvider.getLayoutById(data.layoutId)
		} else {
			layoutQuery = layoutProvider.getLayoutFromData(data)
		}
		layoutQuery
			.then(setDynamicLayout)
			.catch((error) => {
				console.error(`withDynamicLayout: error fetching layout ${error.message}`, error);
				setLoadingError(error);
			});
	}, [JSON.stringify(data)]);

	if (usesDynamicLayout) {
		if (dynamicLayout) {
			const combinedLayout = {
				...dynamicLayout,
				...src
			};
			const combinedStores = {
				...stores,
				...dynamicLayout.stores
			};
			return <Widget src={combinedLayout} stores={combinedStores} {...props} />
		} else if (loadingError) {
			const errorWidget = layoutProvider && layoutProvider.getErrorWidget();
			if (errorWidget) {
				const errorWithStore = { 
					...errorWidget, 
					datastores: {
						...errorWidget.datastores,
						error: {
							"$config": {
								name: 'error',
								storage: loadingError,
								type: 'simple',
							},
						},
					}
				};
				return <Widget src={errorWithStore} stores={stores} />
			}
		} else { // is loading
			const placeholder = layoutProvider && layoutProvider.getPlaceholder();
			if (placeholder) {
				// don't pass props to avoid accidentally injecting content
				return <Widget src={placeholder} stores={stores} />
			}
		}
		return null;
	} else {
		return <Widget src={src} stores={stores} {...props} />;
	}
};
