export class InjectionToken {
	token;

	_innerRef;
	_instance;
	_factory;

	get ref() {
		return this._innerRef;
	}

	get instance() {
		return this._instance;
	}

	get factory() {
		return this._factory;
	}

	constructor(token, instance) {
		if (typeof token === 'string') {
			this._innerRef = token;
		} else {
			this._innerRef = token.identifier || token.name || token.constructor.name;
			this._factory = token;
		}

		if (instance) {
			this._instance = instance;
		}

		this.token = Symbol(this._innerRef);
	}

	canConstruct() {
		return !!this._instance || !!this._factory;
	}
}
