import { Alert } from '@a-ui/react';

import ProductDetail from 'components/ProductDetail';
import FormNavigation from '../FormNavigation';

const OverviewStep = ({
  appointment,
  product,
  previous,
  onSubmit,
  index,
  data,
  steps
  //onChange,
}) => {
  const dataDuration = data.duration || {};
  const dataStepTimeSlot = data.timeslot || {};
  const dataStepBasicInfo = data.basicInfo || {};
  const dataStepExtraFields = data.extraInfo || {};
  const onSubmitAppointment = () => {
    //window.antwerpAppPostMessage(data);
    onSubmit();
  };

  if (data.length === 0) return null;

  return (
    <>
      <div className="u-margin-top u-margin-bottom-xl">
        <h3>Controleer je afspraak</h3>
        <br />
        <ProductDetail
          product={product}
          timeSlot={dataStepTimeSlot.slot}
          duration={dataDuration.duration}
          amount={dataDuration.amount}
          location={dataStepTimeSlot.location}
          user={dataStepBasicInfo}
          extraFields={dataStepExtraFields}
        />

        {product.requisites && (
          <div className="u-margin-top-xl" style={{ wordBreak: 'break-word'}}>
            <Alert theme="warning" inline title={{ label: 'Breng dit zeker mee' }}>
              <div
                className="u-margin-top"
                dangerouslySetInnerHTML={{
                  __html: product.requisites
                }}
              />
            </Alert>
          </div>
        )}
      </div>

      <FormNavigation
        current={index}
        valid={true}
        loading={appointment.loading}
        steps={steps}
        previous={previous}
        onSubmit={onSubmitAppointment}
      />
    </>
  );
};

export default OverviewStep;
