import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import { nlBE } from 'date-fns/locale';
import { Datepicker, Label, TextField } from '@a-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import FormNavigation from '../FormNavigation';
import { ScrollTo, createError } from 'components/Helpers';
import { validationSchema, initialValues } from 'validation/BasicInfoStep';
import { UserContext } from 'contexts/User';

const BasicInfoContainer = styled.div`
  .a-input {
    margin-bottom: 1.5rem;
  }
`;

const BasicInfoStep = ({
  handleSubmit,
  product,
  onChange,
  index,
  mode,
  previous,
  next,
  stepData,
  data,
  steps
}) => {
  const session = useContext(UserContext);
  const [touched, setTouched] = useState({});
  const dataStepTimeSlot = data.timeslot || {};

  useEffect(() => {
    ScrollTo('top');
  }, []);

  const {
    control,
    getValues,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues(session, stepData),
    resolver: yupResolver(validationSchema(product))
  });

  const goToNextStep = () => {
    onChange('basicInfo', getValues());
    next();
  };

  const goToPrevioustStep = () => {
    onChange('basicInfo', getValues());
    previous();
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} noValidate className="u-margin-bottom-xl">
        <div className="u-margin-top">
          <div className="u-margin-bottom title-appointment"><Label
            type="primary"
          ><b>{product.name}</b> op <b>{format(parseISO(dataStepTimeSlot.slot), 'EEEE dd/MM/yyyy', { locale: nlBE })}</b>.
          </Label>
          </div>
          {mode !== 'kiosk' &&
            <div className="u-margin-bottom">
              <h3>Wie komt er naar de afspraak?</h3>
            </div>
          }
          <BasicInfoContainer>
            <input type="hidden" name="userId" value={getValues('userId')} />
            <Controller
              control={control}
              name="firstName"
              render={({ field: { onChange, value } }) => (
                <TextField
                  name="firstName"
                  label="Voornaam"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  onBlur={(e) => setTouched({ ...touched, [e.target.name]: true })}
                  required
                  description={touched.firstName && errors.firstName && createError(errors.firstName.message)}
                  state={touched.firstName && errors.firstName && 'error'}
                />
              )}
            />

            <Controller
              control={control}
              name="lastName"
              render={({ field: { onChange, value } }) => (
                <TextField
                  name="lastName"
                  label="Achternaam"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  onBlur={(e) => setTouched({ ...touched, [e.target.name]: true })}
                  required
                  description={touched.lastName && errors.lastName && createError(errors.lastName.message)}
                  state={touched.lastName && errors.lastName && 'error'}
                />
              )}
            />

            <Controller
              control={control}
              name="birthDate"
              render={({ field: { onChange, value } }) => (
                <Datepicker
                  id="birthDate"
                  name="birthDate"
                  format="dd/MM/yyyy"
                  iconButtonLabel="Open kalender"
                  invalidDateText="Ongeldige geboortedatum - Gebruik het formaat: dd/mm/jjjj"
                  label="Geboortedatum"
                  onChange={(date) => {
                    onChange(date);
                  }}
                  value={value}
                  required
                  inputProps={{
                    onChange: () => onChange(),
                    description: (touched.birthDate && errors.birthDate) ? createError(errors.birthDate.message) : 'Gebruik het formaat: dd/mm/jjjj'
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="phone"
              render={({ field: { onChange, value } }) => (
                <TextField
                  name="phone"
                  label="Telefoonnummer"
                  type="tel"
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  onBlur={(e) => setTouched({ ...touched, [e.target.name]: true })}
                  value={value}
                  required
                  description={touched.phone && errors.phone && createError(errors.phone.message)}
                  state={touched.phone && errors.phone && 'error'}
                />
              )}
            />

            <Controller
              control={control}
              name="postalCode"
              render={({ field: { onChange, value } }) => (
                <TextField
                  name="postalCode"
                  label="Postcode"
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  onBlur={(e) => setTouched({ ...touched, [e.target.name]: true })}
                  value={value}
                  required
                  description={touched.postalCode && errors.postalCode && createError(errors.postalCode.message)}
                  state={touched.postalCode && errors.postalCode && 'error'}
                />
              )}
            />

            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value } }) => (
                <TextField
                  name="email"
                  label="E-mailadres"
                  type="email"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  onBlur={(e) => setTouched({ ...touched, [e.target.name]: true })}
                  required
                  description={touched.email && errors.email && createError(errors.email.message)}
                  state={touched.email && errors.email && 'error'}
                />
              )}
            />
          </BasicInfoContainer>
        </div>
      </form>

      <FormNavigation current={index} valid={isValid} previous={goToPrevioustStep} next={goToNextStep} steps={steps} />
    </React.Fragment>
  );
};

export default BasicInfoStep;
