import Sha512 from 'js-sha512';
import ErrorHandler from './ErrorHandler';

export function createAppointment(body) {
  return fetch('/api/appointments', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(ErrorHandler)
    .then((response) => response.json());
}

export function updateAppointment(id, body, email = '') {
  let url = `/api/appointments/${id}`;
  if (email) {
    url += `?email=${Sha512(email.trim().toLowerCase())}`;
  }

  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(ErrorHandler)
    .then((response) => response.json());
}

export function fetchAppointment(id, email = '') {
  let url = `/api/appointments/${id}`;
  if (email) {
    url += `?email=${Sha512(email.trim().toLowerCase())}`;
  }

  return fetch(url, {
    method: 'GET'
  })
    .then(ErrorHandler)
    .then((response) => response.json());
}

export function getAppointmentIdBySecureLink(secureLink) {
  const url = `/api/appointments/${secureLink}/idbysecurelink`;

  return fetch(url, {
    method: 'GET'
  })
    .then(ErrorHandler)
    .then((response) => response.json());
}

export function deleteAppointment(id, email = '') {
  let url = `/api/appointments/${id}`;
  if (email) {
    url += `?email=${Sha512(email.trim().toLowerCase())}`;
  }
  return fetch(url, {
    method: 'DELETE'
  })
    .then(ErrorHandler)
    .then((response) => response.statusText);
}

export function fetchAppointmentQrCode(id) {
  let url = `/api/appointments/${id}/qr-code`;
  return fetch(url, {
    method: 'GET'
  })
    .then(ErrorHandler)
    .then((response) => response.json());
}

export function fetchAppointments() {
  const url = '/api/appointments';
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Astad-Applicationid': 'ab27f6bb3ef44d46453645802c87b59a'
    }
  })
    .then(ErrorHandler)
    .then((response) => response.json());
}
