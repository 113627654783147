import { InjectionToken } from './InjectionToken';

export class Injector {
	static identifier = 'Injector';
	registry = new Map();

	token;
	tokenRegistry = new Map();

	parentInjector;

	constructor(parentInjector) {
		this.token = new InjectionToken(Injector.identifier, this);
		this.tokenRegistry.set(Injector.identifier, this.token);

		this.parentInjector = parentInjector;
	}

	has(dep) {
		const token = this.getInjectionToken(dep);

		return this.registry.has(token);
	}

	get(dep, newInstance = false) {
		const token = this.getInjectionToken(dep);

		if (token === this.token) {
			return this;
		}

		if (this.registry.has(token)) {
			return this.registry.get(token);
		}

		if (!newInstance && this.parentInjector && this.parentInjector.registry.has(token)) {
			return this.parentInjector.registry.get(token);
		}

		if (!token.canConstruct()) {
			throw new Error(`Cannot resolve dependency with name "${dep}"!`);
		}

		this.set(token);

		return this.registry.get(token);
	}

	set(token) {
		if (this.registry.has(token)) {
			throw new Error(`Dependency with name "${token.ref}" already set!`)
		}

		const instance = this.getInstance(token);

		this.tokenRegistry.set(token.ref, token);
		this.registry.set(token, instance);
	}

	getInstance(token) {
		if (token.instance) {
			return token.instance;
		}

		const dependencies = this.resolveDependencies(token.factory);

		return new token.factory(...dependencies);
	}

	getInjectionToken(dep, instance) {
		if (dep instanceof InjectionToken) {
			return dep;
		}

		if (this.tokenRegistry.has(dep)) {
			return this.tokenRegistry.get(dep);
		}

		const token = new InjectionToken(dep, instance);

		if (this.tokenRegistry.has(token.ref)) {
			return this.tokenRegistry.get(token.ref);
		}

		return token;
	}

	resolveDependencies(factory) {
		if (!factory.Dependencies) {
			return [];
		}

		return factory.Dependencies.map((dep) => this.get(dep));
	}
}

