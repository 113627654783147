export const getBreakpointScreenSize = (screenSizes) => {
	return screenSizes.reduce((acc, [key, value]) => {
		if (!acc) {
			return {
				key,
				value,
			};
		}

		const max = Math.max(acc.value, value);

		if (max === value) {
			return {
				key,
				value,
			};
		}

		return acc;
	}, null);
};

export const findBreakpoint = (breakpoints, { size, defaultBreakPoint }) => {
	const activeBreakpoint = breakpoints.find(({ key }) => key === size.key);

	if (activeBreakpoint) {
		return activeBreakpoint;
	}

	if (defaultBreakPoint) {
		return findBreakpoint(breakpoints, { size: { key: defaultBreakPoint } });
	}

	return null;
};
