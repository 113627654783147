import { WidgetType } from "../enums/widget-type.enum";

export class WidgetRegistry {
	static identifier = 'WidgetRegistry';

	widgets = {};
	widgetFallback = { identifier: 'FieldGroupWidget' };

	setWidgetFallback(widgetFallback) {
		this.widgetFallback = widgetFallback;
	}

	register(identifier, factory) {
		if (this.widgets[identifier]) {
			throw new Error(`Widget already registered! Identifier: ${identifier}`);
		}

		this.widgets[identifier] = factory;
	}

	getWidget(identifier, { fallback = true } = {}) {
		if (!this.widgets[identifier]) {
			console.warn(`Unknown widget! Identifier: ${identifier}`);

			return fallback ? this.getWidget(this.widgetFallback.identifier, { fallback: false }) : null;
		}

		return this.widgets[identifier] ? {
			...this.widgets[identifier],
			widgetType: this.widgets[identifier].component ? WidgetType.component : WidgetType.tag,
		} : null;
	}

	hasWidget(identifier) {
		return this.widgets.hasOwnProperty(identifier);
	}
}
