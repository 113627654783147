import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import useAppModus from 'hooks/useAppModus';
import { UserContext } from 'contexts/User';
import { Header, Content, Loader } from 'components/Layout';
import { Gtm } from 'components/Gtm';
import { ScrollTo } from 'components/Helpers';
import AppointmentDetail from 'components/ViewAppointment/AppointmentDetail';
import AppointmentDeleted from 'components/ViewAppointment/AppointmentDeleted';
import AppointmentSecurity from 'components/ViewAppointment/AppointmentSecurity';
import { DELETE_APPOINTMENT_SUCCESS, fetchAppointmentSuccess } from 'actions/types';
import { getAppointment, removeAppointment } from 'actions/Appointments';

const ViewAppointment = () => {
  const mode = useAppModus();
  const [email, setEmail] = useState('');
  let { id } = useParams();
  const location = useLocation();
  const state = location.state ? location.state : {};
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const session = useContext(UserContext);
  const { appointment } = useSelector((state) => ({
    appointment: {
      loading: state.AppointmentReducer.loading,
      type: state.AppointmentReducer.type,
      error: state.AppointmentReducer.error,
      data: state.AppointmentReducer.appointment
    }
  }));

  useEffect(() => {
    if (state.created) {
      const data = {
        appnaam: 'afspraken',
        toolnaam: window.location.pathname.substr(1) || 'afspraken',
        succes: 'formulier verzonden'
      };

      if (state.email) {
        data.email = state.email;
      }

      if (session.isLoggedin) {
        data['a-profiel'] = true;
      }

      if (state.telephone) {
        data.telefoon = state.telephone;
      }
      Gtm.push('page-impression', {
        url: window.location.href,
        path: window.location.pathname,
        paginanaam: 'Afspraak maken | Antwerpen.be'
      });

      Gtm.push('toollaststep-impression', data);
    }
    ScrollTo('top');

    // COMPONENT WILL MOUNT CODE
    // If appointment is passed from create/edit to this route
    if (state.appointment) {
      dispatch(fetchAppointmentSuccess(state.appointment));
    }

    if (!appointment.loading && !state.appointment) {
      dispatch(getAppointment(id, state.email));
    }
  }, []);

  const submitEmail = (email) => {
    setEmail(email);
    dispatch(getAppointment(id, email));
  };

  const editAppointment = () => {
    navigate(`/afspraak/${appointment.data.id}/wijzigen${location.search}`, {
      state: {
        email: appointment.data.form.email && !appointment.data.user ? appointment.data.form.email : ''
      }
    });
  };

  const deleteAppointment = () => {
    dispatch(removeAppointment(id, appointment.data.form.email));
  };

  const headerTitle = appointment.type === DELETE_APPOINTMENT_SUCCESS ? 'Afspraak annuleren' : 'Bevestiging afspraak';

  if (appointment.error) {
    return (
      <AppointmentSecurity
        session={session}
        email={email}
        status={appointment.error.status}
        submitEmail={submitEmail}
      />
    );
  }

  if (appointment.loading || isEmpty(appointment.data)) {
    return <Loader />;
  }

  return (
    <>
      <Header title={headerTitle} subTitle={appointment.data.product.name} session={session} mode={mode} />
      <Content mode={mode}>
        {appointment.type === DELETE_APPOINTMENT_SUCCESS ? (
          <AppointmentDeleted appointment={appointment.data} />
        ) : (
          <AppointmentDetail
            appointment={appointment.data}
            product={appointment.data.product}
            editAppointment={editAppointment}
            deleteAppointment={deleteAppointment}
          />
        )}
      </Content>
    </>
  );
};

export default ViewAppointment;
