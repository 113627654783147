import { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router';

const createError = (error) => {
  if (error) {
    return <span className="u-text-danger">{error}</span>;
  }
};

function ScrollToTopFunction({ children }) {
  const location = useLocation();

  useEffect(() => {
    ScrollTo('top');
  }, [location]);

  return <Fragment>{children}</Fragment>;
}

function ScrollTo(direction) {
  let wrapper;
  switch (direction) {
    case 'top':
      wrapper = document.querySelector('#main');
      break;
    default:
      wrapper = document.querySelector('#main');
      break;
  }

  if (wrapper) {
    wrapper.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

const ScrollToTop = ScrollToTopFunction;
export { createError, ScrollToTop, ScrollTo };
