import { addStyle } from "./dom";
import react from "react";
import reactDOM from "react-dom";
import { createRoot } from "react-dom/client";

const addStyles = (el, styles, identifier) => {
	styles.forEach((style) => {
		addStyle(style, el, identifier);
	});
};

export const renderWidget =
	([widgetFactory, el], { id, stylesheetUrls = [] } = {}) =>
		({ children = null, ...props } = {}) => {
			if (reactDOM && widgetFactory && el) {
				const target = el && el.shadowRoot ? el.shadowRoot : el;
				if (!target) {
					return;
				}
				const appliedStyles = target.querySelectorAll("style");
				const widget = react.createElement(widgetFactory, props, children);
				const root = createRoot(target);
				root.render(widget);
				addStyles(el.shadowRoot, [...stylesheetUrls, ...appliedStyles], id);
			}
		};
