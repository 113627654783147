import { BehaviorSubject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { clone, pathOr } from 'ramda';

import { isStatePath } from '../utils/state-path';

export class Storage {
	identifier = 'Storage';
	state = new BehaviorSubject(null);

	constructor(storage, identifier) {
		if (identifier) {
			this.identifier = identifier;
		}
		this.setState(storage);
	}

	getState() {
		return this.state;
	}

	snapshot() {
		return this.state.getValue();
	}

	setState(newState) {
		this.state.next(newState);
	}

	select(dataPath) {
		return this.state.pipe(
			map((state) => {
				if (typeof dataPath === 'string') {
					return state[dataPath];
				}

				const cloneCurrentState = clone(this.getState().getValue());

				const property = dataPath && (dataPath.length > 0) && dataPath[dataPath.length - 1];
				if ((property !== 'isMultiple') && !isStatePath(cloneCurrentState, dataPath)) {
					console.warn(`Data path (${dataPath}) does not exist for "${this.identifier}"!`);
				}

				return pathOr(null, dataPath, state);
			}),
			distinctUntilChanged(),
		);
	}

	reset() {
		this.state.next(null);
	}
}
