import { useEffect } from 'react';

import { Loader } from 'components/Layout';

const Redirect = ({
  url
}) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return <Loader />;
};

export default Redirect;