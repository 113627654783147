const CookieConsentConfig = {
  'title': 'Cookies op antwerpen.be',
  'intro': 'Antwerpen.be gebruikt cookies. Als je optionele cookies weigert, worden alleen cookies geplaatst die nodig zijn voor de werking van de site. Bepaalde inhoud (zoals van YouTube) is alleen beschikbaar als je marketingcookies aanvaardt via ‘Alle cookies aanvaarden’ of ‘Voorkeuren instellen’. Je kan je cookievoorkeuren altijd aanpassen via ‘Cookies’ onderaan de website. Lees onze <a href=\'https://www.antwerpen.be/info/cookiebeleid-antwerpenbe\' target=\'_blank\'>cookieverklaring</a> als je er meer over wil weten.',
  'cookieConfig': [
    {
      'name': 'Noodzakelijk',
      'showSwitch': false,
      'enabled': true,
      'description': 'Noodzakelijke cookies zijn cookies die ervoor zorgen dat alle onderdelen van de website goed werken. Je kan deze niet toestaan of weigeren.',
      'open': false,
      'cookies': [
        {
          'cookie': 'AOS',
          'type': 'Eerste partij',
          'duration': 'sessie',
          'description': 'AOS of het Antwerp Operating System plaatst sessiegebaseerde cookies op je computer waarmee gebruikers die terugkeren naar antwerpen.be kunnen geïdentificeerd worden. Dit verhoogt het gebruiksgemak: de gebruiker blijft aangemeld en moet per app niet opnieuw aanmelden (Single Sign On).'
        },
        {
          'cookie': 'eidToken',
          'type': 'Eerste partij',
          'duration': '30 minuten',
          'description': 'Het eidToken plaatst sessiegebonden cookies op je computer waarmee gebruikers die terugkeren naar antwerpen.be kunnen geïdentificeerd worden. Dit verhoogt het gebruiksgemak en zorgt er voor dat de gebruiker bepaalde gegevens niet opnieuw moet ingeven.'
        },
        {
          'cookie': 'authsessionid',
          'type': 'Eerste partij',
          'duration': '8 uur',
          'description': 'Deze cookie identificeert de gebruiker op de huidige webpagina. De duurtijd wordt verlengd als je actief blijft op de site.'
        },
        {
          'cookie': 'dgp.auth.ssoKey',
          'type': 'Eerste partij',
          'duration': '1 jaar',
          'description': 'dgp.auth.ssoKey plaatst cookies op je computer om je laatst gebruikte aanmeldkeuze of het laatst gekozen authenticatiemiddel te onthouden.'
        },
        {
          'cookie': 'cookieconsent_prod',
          'type': 'Eerste partij',
          'duration': '1 jaar',
          'description': 'Deze cookie houdt je cookievoorkeuren bij.'
        }
      ]
    },
    {
      'name': 'Analytisch',
      'showSwitch': true,
      'enabled': false,
      'description': 'Analytische cookies meten het websitebezoek. Deze gegevens laten ons toe om de structuur, navigatie en inhoud van onze website te verbeteren.',
      'open': false,
      'cookies': [
        {
          'cookie': 'Google analytics',
          'type': 'Derde partij',
          'duration': '1 minuut tot 2 jaar',
          'description': 'Google Analytics plaatst cookies om het websitebezoek te meten en de paginaweergaven te tellen. Deze gegevens laten ons toe om de structuur, navigatie en inhoud van onze website te verbeteren.',
          'subCookies': [
            {
              'name': '_ga',
              'duration': '2 jaar'
            },
            {
              'name': '_gid',
              'duration': '1 dag'
            },
            {
              'name': '_gat',
              'duration': '1 min'
            }
          ]
        },
        {
          'cookie': 'Hotjar',
          'type': 'Derde partij',
          'duration': '2 minuten - 1 jaar',
          'description': 'Hotjar is een analyse- en feedbacktool die ons helpt om te begrijpen hoe antwerpen.be wordt gebruikt. Hotjar plaatst geanonimiseerde cookies op je computer die toelaten om het gedrag van de bezoeker over de pagina’s te volgen en om de bezoekerspeilingen te controleren.',
          'subCookies': [
            {
              'name': '_hjid',
              'duration': '1 jaar'
            },
            {
              'name': '_hjAbsoluteSessionInProgress',
              'duration': '30 minuten'
            },
            {
              'name': '_hjTLDTest',
              'duration': 'sessie'
            },
            {
              'name': '_hjCachedUserAttributes',
              'duration': 'sessie'
            },
            {
              'name': '_hjIncludedInPageviewSample',
              'duration': '2 minuten'
            }
          ]
        }
      ]
    },
    {
      'name': 'Marketing',
      'showSwitch': true,
      'enabled': false,
      'description': 'Je moet marketing-cookies inschakelen om op de website inhoud te tonen die afkomstig is van derde partijen zoals YouTube of Soundcloud.',
      'open': false,
      'cookies': [
        {
          'cookie': 'Youtube',
          'type': 'Derde partij',
          'duration': 'sessie - 6 maanden',
          'description': 'Via de dienst YouTube kan je video’s bekijken die we op antwerpen.be plaatsen. YouTube zet mogelijk één of meerdere cookies op je computer om je surfgedrag te registreren en om voor jou relevante video’s te kunnen suggereren. Deze cookies worden gezet door YouTube en zijn noodzakelijk voor het gebruik van deze functionaliteit.',
          'subCookies': [
            {
              'name': 'VISITOR_INFO1_LIVE',
              'duration': '6 maanden'
            },
            {
              'name': 'YSC',
              'duration': 'sessie'
            },
            {
              'name': 'GPS',
              'duration': 'sessie'
            }
          ]
        },
        {
          'cookie': 'Crowdsignal',
          'type': 'Derde partij',
          'duration': '1 dag',
          'description': 'Via antwerpen.be verzamelt stad Antwerpen graag je mening of stem om de dienstverlening van de stad te optimaliseren. We gebruiken hiervoor de dienst Crowdsignal. Zij plaatsen één of meerdere cookies op je computer om je stem bij te houden. Deze cookies worden gezet door Crowdsignal en zijn noodzakelijk voor het gebruik van deze functionaliteit.'
        },
        {
          'cookie': 'Twitter',
          'type': 'Derde partij',
          'duration': '30 dagen',
          'description': 'Antwerpen.be integreerde de Twitter-widget van Twitter. Als je onze website bezoekt, laad je automatisch de widget voor weergave en ontvangt Twitter bepaalde informatie zoals je IP-adres, browsertype, besturingssysteem en het adres van de website waar de widget is geïntegreerd, en plaatst een cookie die je browser herkent. Twitter kan theoretisch herkennen welke pagina’s je op verschillende websites bezocht, op voorwaarde dat de widget daar is geïmplementeerd. Op Twitters eigen account gebruikt Twitter de gegevens om inhoud voor jou op maat te maken op basis van je bezoeken aan websites van derden, en kan het suggesties geven voor mensen om te volgen op Twitter als je een Twitter-account hebt. Antwerpen.be heeft geen invloed op de gegevens die Twitter verzamelt, verwerkt en gebruikt wanneer je onze website bezoekt.'
        },
        {
          'cookie': 'Soundcloud sc_anonymous_id',
          'type': 'Derde partij',
          'duration': '10 jaar',
          'description': 'De dienst Soundcloud biedt originele audiofragmenten aan. Ook antwerpen.be maakt hiervan gebruik. Soundcloud plaatst cookies op je computer om de gebruikerservaring te verbeteren. Deze cookie wordt gezet door Soundcloud en is noodzakelijk voor het gebruik van deze functionaliteit.'
        },
        {
          'cookie': 'Spotify',
          'type': 'Derde partij',
          'duration': '1 dag - 1 jaar',
          'description': 'Je kan de Spotify-service gebruiken om te luisteren naar audio die we op antwerpen.be plaatsen. Spotify kan een of meer cookies plaatsen om gebruikersinteractie en voorkeuren in context met audio-inhoud te registreren. Dit kan dienen voor statistieken en marketingdoeleinden. Deze cookies worden geplaatst door Spotify en zijn noodzakelijk om deze functionaliteit te gebruiken.',
          'subCookies': [
            {
              'name': 'Sp_landing',
              'duration': '1 dag'
            },
            {
              'name': 'Sp_t',
              'duration': '1 jaar'
            }
          ]
        },
        {
          'cookie': 'Google DoubleClick IDE',
          'type': 'Derde partij',
          'duration': '1.5 jaar',
          'description': 'Google DoubleClick plaatst automatisch een cookie omdat er YouTube-filmpjes geëmbed zijn in antwerpen.be. De cookie trackt over verschillende websites heen welke advertenties er getoond worden in een browser. Zo weet een adverteerder hoe vaak zijn advertentie getoond werd. Noot: op antwerpen.be worden geen advertenties getoond.',
          'subCookies': [
            {
              'name': 'ID',
              'duration': '1.5 jaar'
            },
            {
              'name': 'DSID',
              'duration': '1.5 jaar'
            }
          ]
        }
      ]
    }
  ],
  'nonBlocking': false,
  'cookiePolicy': 'https://www.antwerpen.be/info/cookiebeleid-antwerpenbe'
};

export default CookieConsentConfig;