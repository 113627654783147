import isObject from 'lodash/isObject';
import {
  CREATE_NEW_APPOINTMENT_BEGIN,
  CREATE_NEW_APPOINTMENT_SUCCESS,
  CREATE_NEW_APPOINTMENT_FAILURE,
  UPDATE_APPOINTMENT_BEGIN,
  UPDATE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_FAILURE,
  FETCH_APPOINTMENT_BEGIN,
  FETCH_APPOINTMENT_SUCCESS,
  FETCH_APPOINTMENT_FAILURE,
  DELETE_APPOINTMENT_BEGIN,
  DELETE_APPOINTMENT_SUCCESS,
  DELETE_APPOINTMENT_FAILURE,
  RESET_APPOINTMENT,
  CLEAR_APPOINTMENT_ERRORS,
  FETCH_APPOINTMENTS_BEGIN,
  FETCH_APPOINTMENTS_SUCCESS,
  FETCH_APPOINTMENTS_FAILURE
} from '../actions/types';

const initialState = {
  appointment: {},
  loading: false,
  error: null
};

export function AppointmentReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_NEW_APPOINTMENT_BEGIN:
    case FETCH_APPOINTMENT_BEGIN:
    case UPDATE_APPOINTMENT_BEGIN:
    case DELETE_APPOINTMENT_BEGIN:
      return {
        ...state,
        type: action.type,
        loading: true,
        error: null
      };
    case CREATE_NEW_APPOINTMENT_SUCCESS:
    case FETCH_APPOINTMENT_SUCCESS:
    case UPDATE_APPOINTMENT_SUCCESS:
    case DELETE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        type: action.type,
        loading: false,
        appointment: isObject(action.payload.appointment) ? action.payload.appointment : state.appointment,
        error: null
      };
    case CREATE_NEW_APPOINTMENT_FAILURE:
    case FETCH_APPOINTMENT_FAILURE:
    case UPDATE_APPOINTMENT_FAILURE:
    case DELETE_APPOINTMENT_FAILURE:
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.payload.error,
        appointment: state.appointment
      };
    case RESET_APPOINTMENT:
      return initialState;
    case CLEAR_APPOINTMENT_ERRORS:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
}

export function AppointmentsReducer(state = { ...initialState, appointments: {} }, action) {
  switch (action.type) {
    case FETCH_APPOINTMENTS_BEGIN:
      return {
        ...state,
        type: action.type,
        loading: true,
        error: null
      };
    case FETCH_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        type: action.type,
        loading: false,
        appointments: isObject(action.payload.appointments) ? action.payload.appointments : state.appointments,
        error: null
      };
    case FETCH_APPOINTMENTS_FAILURE:
      return {
        ...state,
        type: action.type,
        loading: false,
        error: action.payload.error,
        appointments: state.appointments
      };
    default:
      return state;
  }
}
