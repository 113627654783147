import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import { createError } from 'components/Helpers';

const LocationWidget = window.auiEmbeddableWidgets.reactComponent(
  'https://widgets.antwerpen.be/definitions/location-picker-v2.json',
  { React, ReactDOM }
);

const Location = ({ field, errors, value, onChange }) => {
  return (
    <>
      <label
        className={classNames('u-margin-bottom-xs', {
          required: field.validators.some((validator) => validator.type === 'required')
        })}
      >
        {field.label}
      </label>
      <LocationWidget
        initialLocation={value}
        onLocationSelect={(result) => onChange(field.name, result)}
        className="location-widget"
      />
      {errors[field.name] && errors[field.name].type === 'required' && createError(errors[field.name].message)}
    </>
  );
};

export default Location;
