import { isNil, propOr } from 'ramda';

import { isObject } from './is-object';

export const getChildrenContent = (content, slotId) => {
	const widgetContent = propOr(content, slotId, content);

	if (isNil(widgetContent)) {
		return null;
	}

	return isObject(widgetContent) ? widgetContent : { value: widgetContent };
};
