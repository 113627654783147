import React, { useContext } from 'react';
import {
  Button,
  Header as AppHeader,
} from '@a-ui/react';

import UserContext from 'contexts/User';
import { getEnv } from 'hooks/config';

const env = getEnv();
const backendUrl = env === 'local' ? 'http://localhost:2000' : '';
const loginUrl = `${backendUrl}/auth/login`;
const logoutUrl = `${backendUrl}/auth/logout`;

const CONTACT_MENU_ITEM = {
  label: 'Contact',
  onClick: () => console.log('click'),
  addOn: {
    iconProps: {
      name: 'alert-circle',
    },
    align: 'left',
    type: 'icon',
  },
};

const Header = () => {
  const { user, isLoggedIn } = useContext(UserContext);
  const fromUrl = env === 'local' ? 'http://localhost:3000' : window.location.href;

  const redirect = () => {
    window.location.href = `${loginUrl}?fromUrl=${fromUrl}`;
  };

  const logout = () => {
    window.location.href = `${logoutUrl}?fromUrl=${fromUrl}`;
  };

  const menuItems = isLoggedIn ? [
    CONTACT_MENU_ITEM,
    {
      addOn: {
        avatarProps: {
          image: user.avatarUrl,
        },
        type: 'avatar',
      },
      flyout: {
        children: <Button
          onClick={logout}
          theme="danger"
        >
          Uitloggen
        </Button>,
        orientation: 'bottom-right',
      },
      label: user?.assuranceLevel === 'low' ? user?.phonePrimary : `${user.firstName} ${user.lastName}`,
    }] : [
    CONTACT_MENU_ITEM,
    {
      label: 'Inloggen',
      onClick: redirect,
    }
  ];

  return (
    <React.Fragment>
      <AppHeader
        menuItems={menuItems}
      />
    </React.Fragment>
  );
};

export default Header;
