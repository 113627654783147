const getEnv = () => {
  if (/^(.*)-o\.antwerpen\.be/i.test(window.location.origin)) {
    return 'dev';
  }
  if (/^(.*)-a\.antwerpen\.be/i.test(window.location.origin)) {
    return 'acc';
  }
  if (/^(.*)\.antwerpen\.be/i.test(window.location.origin)) {
    return 'prod';
  }

  return 'local';
};

const getAstadUrl = () => {
  switch (getEnv().toLowerCase()) {
    case 'local': return 'https://www.dev.dcs.antwerpen.be';
    case 'dev': return 'https://www.dev.dcs.antwerpen.be';
    case 'acc': return 'https://www.acc.dcs.antwerpen.be';
    default:
      return 'https://www.antwerpen.be';
  }
}

const useConfig = () => {
  const config = {
    development: {
    },
    acceptance: {
    },
    production: {
    },
  };

  return config[getEnv()];
};

export {
  useConfig,
  getEnv,
  getAstadUrl,
};
