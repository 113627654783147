import { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useAppModus from 'hooks/useAppModus';
import { Header, Content } from 'components/Layout';
import { UserContext } from 'contexts/User';
import { getProducts } from 'actions/Products';
import SelectProduct from 'components/SelectAppointment/SelectProduct';
import StartAppointment from 'components/SelectAppointment/StartAppointment';

const SelectAppointment = () => {
  const mode = useAppModus();
  const session = useContext(UserContext);

  const [selectedProduct, setSelectedProduct] = useState('');
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();

  const { products } = useSelector((state) => ({
    products: {
      loading: state.productsReducer.loading,
      error: state.productsReducer.error,
      data: state.productsReducer.products
    },
  }));

  useEffect(() => {
    dispatch(getProducts());
  }, []);

  const onChangeProduct = (event) => {
    const product = event.target.value;
    setSelectedProduct(products.data.find((p) => p.slug === product));
  };

  const onSubmit = () => {
    navigate(`/${selectedProduct.slug}${location.search}`);
  };

  return (
    <>
      <Header session={session} mode={mode} />
      <Content mode={mode}>
        <div className="u-margin-top row">
          <div className="col-xs-12">
            <h3>
              Waarvoor wilt u een afspraak maken?
            </h3>
          </div>
        </div>

        <SelectProduct
          products={products.data}
          selectedProduct={selectedProduct.slug}
          onChange={onChangeProduct}
        />

        <StartAppointment
          selectedProduct={selectedProduct}
          onSubmit={onSubmit}
        />
      </Content>
    </>
  );
};

export default SelectAppointment;
