import { pathOr, propOr } from "ramda";

import { SimpleDataStore } from "../store/SimpleDataStore";

export const getDataStore = (configuration, injector, context) => {
	try {
		const type = propOr(null, "type", configuration);

		if (!type) {
			return null;
		}

		const factory = injector.get(type);

		if (factory) {
			return new factory(configuration, injector, context);
		}

		return null;
	} catch (error) {
		console.log("ERRRORORRRR");
		console.log(error);
		console.warn("Could not initialize datastore!");
		console.error(error);
	}
};

const setupDataStore = (configuration, injector, context) => {
	const store = getDataStore(configuration, injector, context);

	// local stores are not registered for DI but passed as props only
	// new stores with names that match stores in DI are not injected into DI but passed as props only
	if (
		configuration &&
		!configuration.local &&
		store &&
		!injector.has(configuration.name)
	) {
		const token = injector.getInjectionToken(configuration.name, store);

		injector.set(token);
	}

	return store;
};

// Setup each default and custom defined datastore and get ref in injector
export const setupDataStores = (newStores, injector, context) => {
	return Object.entries(newStores).reduce(
		(widgetDataStores, [name, datastoreConfig]) => {
			const configuration = pathOr(null, ["$config"], datastoreConfig);
			const ref = pathOr(null, ["$ref"], datastoreConfig);

			// $config
			if (configuration) {
				const store = setupDataStore(configuration, injector, context);

				widgetDataStores[configuration.name] = store;

				return widgetDataStores;
			}

			// $ref
			if (ref) {
				const store = injector.get(ref) || injector.get(name);

				if (store) {
					widgetDataStores[name] = store;
				}

				if (!injector.has(name)) {
					const token = injector.getInjectionToken(name, store);

					injector.set(token);
				}

				return widgetDataStores;
			}

			return widgetDataStores;
		},
		{}
	);
};

export const setupDataStoreTypes = (datastoreTypes, injector) => {
	(datastoreTypes || [])
		.concat({
			type: "simple",
			factory: SimpleDataStore,
		})
		.forEach(({ type, factory }) => {
			if (injector.has(type)) {
				return;
			}

			const token = injector.getInjectionToken(type, factory);

			injector.set(token);
		});
};

export const defaultDefinedDatastores = (
	datastores,
	context,
	fields,
	stylesheetUrls
) => ({
	astadContext: {
		$config: {
			name: "astad-context",
			storage: {
				result: {
					...context,
					stylesheetUrls,
				},
			},
			type: "simple",
		},
	},
	astadContent: {
		$config: {
			name: "astad-content",
			storage: {
				result: { ...fields },
			},
			type: "simple",
		},
	},
	...datastores,
});
