import { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { UserContext } from 'contexts/User';
import FormSteps from 'components/Wizard/FormSteps';
import { getProduct } from 'actions/Product';
import { resetAppointment } from 'actions/types';
import { newAppointment } from 'actions/Appointments';
import Error from 'components/Errors/Error';

const CreateAppointment = () => {
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const session = useContext(UserContext);
  let { slug } = useParams();
  const { search } = useLocation();
  const mode = searchParams.get('mode') || 'web';

  const dispatch = useDispatch();
  const [step] = useState(0);
  const { product, appointment } = useSelector((state) => ({
    product: {
      loading: state.productReducer.loading,
      error: state.productReducer.error,
      data: state.productReducer.product
    },
    appointment: {
      loading: state.AppointmentReducer.loading,
      type: state.AppointmentReducer.type,
      error: state.AppointmentReducer.error,
      data: state.AppointmentReducer.appointment
    }
  }));

  useEffect(() => {
    dispatch(resetAppointment());
  }, []);

  useEffect(() => {
    if (isEmpty(appointment.data) && isEmpty(product.data)) {
      dispatch(resetAppointment());
      dispatch(getProduct(slug));
    }

    if (!isEmpty(appointment.data)) {
      navigate(`/afspraak/${appointment.data.id}${search}`, {
        state: {
          search: '',
          created: true,
          appointment: appointment.data,
          email: appointment.data.form.email || '',
          telephone: appointment.data.form.phoneNumber || ''
        }
      });
    }
  }, [appointment.data]);

  if (product.error) {
    const status = product.error.status;
    switch (status) {
      case 404:
        return <Error
          title="Afspraak maken"
          fromUrl={`/${slug}`}
          theme="danger"
          error={{
            title: 'Je kan geen afspraak maken voor dit product',
            description: 'Voor het product dat je selecteerde kan je geen afspraak meer maken.'
          }} />;
      case 409:
        return (
          <Error
            error={
              'U kan deze afspraak tijdelijk niet maken.'
            }
          />
        );
      default:
        return <Error error={'Er ging iets mis.'} />;
    }
  }

  if (appointment.error) {
    const status = appointment.error.status;
    switch (status) {
      case 409:
        return <Error
          title="Afspraak maken"
          fromUrl={`/${slug}`}
          theme="danger"
          button={{
            text: 'Maak een nieuwe afspraak',
            url: `/${slug}`
          }}
          error={{
            title: 'Tijdstip niet meer beschikbaar',
            description: 'Het tijdstip dat je selecteerde voor deze locatie is niet meer beschikbaar.'
          }} />;
      default:
        return <Error
          title="Afspraak maken"
          fromUrl={`/${slug}`}
          theme="danger"
          button={{
            text: 'Probeer opnieuw',
            url: `/${slug}`
          }}
          error={{
            title: 'Er ging iets mis...',
            description: 'Er is een technisch probleem, onze excuses. Probeer het later opnieuw.'
          }} />;
    }
  }

  if (isEmpty(product.data)) {
    return null;
  }

  return (
    <FormSteps
      mode={mode}
      step={step}
      product={product.data}
      data={{}}
      appointment={appointment}
      appointmentType="create"
      session={session}
      submitAppointment={(data) => dispatch(newAppointment(data, product.data))}
    />
  );
};

export default CreateAppointment;
