const fromUrl = encodeURIComponent(window.location.href);

export const isLoggedin = () =>
  fetch(`/api/users/me?fromUrl=${fromUrl}`, { method: 'GET' })
    .then((response) => response.json())
    .catch((error) => error);

export const logout = () =>
  fetch(`/logout?fromUrl=${fromUrl}`, { method: 'GET' })
    .then((response) => response.json())
    .catch((error) => error);
