import React from 'react';

import { useRenderContext } from '../hooks/useRenderContext';
import { setupDataStores } from '../utils/datastores';

export const withDataStores = (Widget) => ({ src, stores, ...props }) => {
	const { injector, debug } = useRenderContext();
	// additional datastores to add to stores
	const { datastores } = src;

	if (!injector) {
		return null;
	}

	const contextStore = injector.get('astad-context');
	const context = contextStore ? contextStore.snapshot() : {};

	let newStores;
	if (datastores) {
		newStores = setupDataStores(datastores, injector, { ...context, debug });
	}

	return <Widget src={src} {...props} stores={{ ...stores, ...newStores }} />;
};
