import DurationStep from './DurationStep';
import Locations from './LocationsStep';
import DatePicker from './DatePickerStep';
import TimeSlot from './TimeSlotStep';
import BasicInfo from './BasicInfoStep';
import ExtraInfo from './ExtraInfoStep';
import Overview from './OverviewStep';

export const Steps = [
  {
    key: 'duration',
    title: 'Aantal',
    component: DurationStep,
    path: '',
    exact: true,
  },
  {
    key: 'locations',
    title: 'Kies locatie(s)',
    component: Locations,
    path: '',
  },
  {
    key: 'date',
    title: 'Kies een datum',
    component: DatePicker,
    path: '',
  },
  {
    key: 'timeslot',
    title: 'Kies een tijdstip',
    component: TimeSlot,
    path: '',
  },
  {
    key: 'extraInfo',
    title: 'Extra Info',
    component: ExtraInfo,
    path: '',
  },
  {
    key: 'basicInfo',
    title: 'Basisinfo',
    component: BasicInfo,
    path: '',
    exact: true,
  },
  {
    title: 'overview',
    component: Overview,
    path: '',
    exact: true,
  }
];
