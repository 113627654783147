import { useSearchParams } from 'react-router-dom';

const useAppModus = () => {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode') || 'web';

  return mode;
};

export default useAppModus;
