import React from 'react';
import { propOr } from 'ramda';

import { getMultiple } from '../utils/get-multiple-widgets';
import { useRenderContext } from '../hooks/useRenderContext';
import { useDataStores } from '../hooks/useDataStores';

/**
 * HOC to generate multiple widgets from state data
 * @deprecated use generateChildren instead
 */
export const withMultiple = (Widget) => ({ 
	src, /* widget configuration */
	stores, /* inherited datastores */
	isMultiple, /* boolean or { value: boolean } */
	...props
}) => {
	const { widgetRegistry } = useRenderContext();
	const widgetState = useDataStores(src, stores);

	const { attributes: defaultAttributes = null } = widgetRegistry.getWidget(src.component) || {};
	const attributes = propOr({}, 'attributes', src);

	const multipleFromAttributes = propOr(false, 'isMultiple', attributes) || propOr(false, 'isMultiple', defaultAttributes);

	const multipleFromProps = !!propOr(isMultiple, 'value', isMultiple);
	const multipleFromContent = !!widgetState && widgetState.isMultiple;

	if (multipleFromProps || multipleFromContent || multipleFromAttributes) {
		// value array for multiple widgets: use from store, fallback to props
		if (!Array.isArray(widgetState) && !widgetState.value && props.value) {
			widgetState.value = props.value;
		}		
		return getMultiple(widgetState, src);
	}

	return <Widget src={src} stores={stores} {...props} />
};
