import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers';

const store = configureStore({
  reducer: rootReducer,
  // initialState: /* your initial state */,
  // devTools: /* whether to enable Redux DevTools extension */,
});

export default store;
