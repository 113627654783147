import { TextField as NativeTextField } from '@a-ui/react';

import { createError } from 'components/Helpers';

const TextField = ({ type = 'text', field, value, onChange, errors }) => (
  <>
    <NativeTextField
      label={field.label}
      name={field.name}
      value={value}
      type={type}
      placeholder={field.placeholder}
      onChange={(e) => onChange(field.name, e.target.value)}
      required={field.validators.find((validator) => validator.type === 'required')}
      description={
        errors[field.name] ? createError(errors[field.name].message) : 'Maximaal 50 karakters'
      }
      state={errors[field.name] && 'error'}
    />
  </>
);

export default TextField;
