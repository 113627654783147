import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import { Alert, Button, Modal } from '@a-ui/react';
import styled from 'styled-components';

import ProductDetail from 'components/ProductDetail';
import { fetchAppointmentQrCode } from 'api/Appointments';

const FormNavigationContainer = styled.div`
  display: flex;
  gap: 1.0rem;
  flex-direction: column;
  margin-top: var(--SPACER-XL);

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const AppointmentDetail = ({ appointment, editAppointment, deleteAppointment }) => {
  const location = useLocation();
  const state = location.state ? location.state : {};
  const [displayDeleteOverlay, setDisplayDeleteOverlay] = useState(false);
  const [qrCode, setQrCode] = useState(null);

  useEffect(() => {
    if (appointment.location.hasTerminal ) {
      fetchAppointmentQrCode(appointment.id).then(result => {
        setQrCode(result?.qrCode);
      });
    }
  }, []);

  const toggleDeleteAppointment = () => {
    setDisplayDeleteOverlay(!displayDeleteOverlay);
  }

  return (
    <div className="u-margin-top">
      <h3 className="u-margin-bottom">Bevestiging afspraak</h3>
      {state.created && (
        <div className="u-margin-bottom-xl">
          <Alert
            inline
            theme="success"
            title={{
              label: 'Je afspraak is gemaakt.'
            }}
            titleId="aui-warning-success-title"
          >
          </Alert>
        </div>
      )}
      {(appointment.location.hasTerminal && qrCode) && (
        <div className="u-margin-bottom-xs u-margin-top-xl">
          <QRCodeSVG
            size={200}
            value={qrCode}
          />
          <p className="u-margin-top-xl u-margin-bottom-xl"><b>Scan deze QR-code aan de zuil als je op je afspraak komt.</b></p>
        </div>
      )}
      <ProductDetail
        product={appointment.product}
        timeSlot={appointment.startTime}
        duration={appointment.duration}
        amount={appointment.form.amount}
        location={appointment.location}
        user={appointment.form}
        extraFields={appointment.form.extraFields}
      />

      {appointment.product.requisites && (
        <div className="u-margin-top-xl u-margin-bottom" style={{ wordBreak: 'break-word'}}>
          <Alert
            inline
            theme="warning"
            title={{
              label: 'Breng dit zeker mee'
            }}
            titleId="aui-warning-requisites-title"
          >
            <div dangerouslySetInnerHTML={{ __html: appointment.product.requisites }} />
          </Alert>
        </div>
      )}

      {!appointment.form.email && !appointment.user && (
        <Button iconLeft="print" onClick={() => window.print()}>
          Afspraak afprinten
        </Button>
      )}

      {((appointment.form.email || appointment.user) && new Date(appointment.startTime) > new Date()) && (
        <FormNavigationContainer>
          <Button
            addOn={{
              type: 'icon',
              align: 'left',
              iconProps: {
                name: 'pencil-write-1'
              }
            }}
            onClick={editAppointment}>
            Wijzig de afspraak
          </Button>
          <Modal
            appRootId="root"
            cancelButton={{
              children: 'Nee, ga terug',
              emphasis: 'medium',
              id: 'aui-modal-cancel',
              onClick: function io() { },
              size: 'small'
            }}
            confirmButton={{
              children: 'Ja, annuleer de afspraak',
              id: 'aui-modal-confirm',
              onClick: () => deleteAppointment(),
              size: 'small'
            }}
            onAfterOpen={function io() { }}
            onClose={function io() { }}
            title={{
              label: 'Afspraak annuleren?'
            }}
            trigger={
              <Button
                addOn={{
                  type: 'icon',
                  align: 'left',
                  iconProps: {
                    name: 'bin'
                  }
                }}
                emphasis="medium"
                theme="danger"
                onClick={toggleDeleteAppointment}>
                Afspraak annuleren
              </Button>
            }
          >
            Weet je zeker dat je je afspraak wil annuleren?
          </Modal>
        </FormNavigationContainer>
      )}
    </div>
  );
};

export default AppointmentDetail;
