import { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { nlBE } from 'date-fns/locale';
import { Label } from '@a-ui/react';

import ExtraFields from 'components/Wizard/Components/ExtraFields/ExtraFields';

import FormNavigation from '../FormNavigation';

const ExtraInfoStep = ({ product, index, previous, next, onChange, stepData, steps, data }) => {
  const [values, setValues] = useState({});
  const [valid, setValid] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const dataStepTimeSlot = data.timeslot || {};

  useEffect(() => {
  }, []);

  const goToNextStep = () => {
    setSubmitting(true);
    if (valid) {
      onChange('extraInfo', {
        ...values
      });
      next();
    }
  };

  return (
    <div className="row">
      <div className="col-xs-12 col-md-6">
        <div className="u-margin-top">
          <h5>Extra info</h5>
          <div className="u-margin-top u-margin-bottom title-appointment"><Label
            type="primary"
          ><b>{product.name}</b> op <b>{format(parseISO(dataStepTimeSlot.slot), 'EEEE dd/MM/yyyy', { locale: nlBE })}</b>.
          </Label>
          </div>
        </div>

        <div className="u-margin-top">
          <ExtraFields
            fields={product.formConfig.extraFields}
            defaultValues={stepData}
            submitting={submitting}
            setValid={setValid}
            setSubmitting={setSubmitting}
            setValues={setValues}
          />

          <FormNavigation current={index} valid={true} steps={steps} previous={previous} next={goToNextStep} />
        </div>
      </div>
    </div>
  );
};

export default ExtraInfoStep;
