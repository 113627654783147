import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { Header, Content } from 'components/Layout';
import Error from '../Errors/Error';
import EmailOverLay from './EmailOverlay';
import { login } from 'actions/Session';
import useAppModus from 'hooks/useAppModus';
import { UserContext } from 'contexts/User';

const AppointmentSecurity = ({ email, status, submitEmail }) => {
  const dispatch = useDispatch();
  const mode = useAppModus();
  const session = useContext(UserContext);

  switch (status) {
    case 401:
      return dispatch(login());
    case 403:
      return <Error
      title="Afspraak maken"
      theme="danger"
      inline={true}
      error={{
        title: 'Deze afspraak werd met een ander profiel aangemaakt.',
      }} />;
    case 404:
      return <Error
      title="Afspraak maken"
      theme="warning"
      error={{
        title:'Afspraak niet gevonden',
        description: 'Deze afspraak bestaat niet meer.'
      }} />;
    case 406:
      return <Error error={{ title: 'U bent niet gemachtigd om deze afspraak te bekijken.'}} inline={false} />;
    case 400:
      return (
        <>
          <Header session={session} mode={mode} />
          <Content mode={mode}>
            <EmailOverLay
              email={email}
              submitEmail={(email) => submitEmail(email)}
              error={'Het ingevulde e-mail is niet correct.'}
            />
          </Content>
        </>
      );
    case 422:
      return <EmailOverLay email={email} submitEmail={(email) => submitEmail(email)} />;
    default:
      return <Error
      title="Afspraak maken"
      theme="warning"
      error={{
        title:'Er ging iets mis...',
        description: 'Er is een technisch probleem, onze excuses. Probeer het later opnieuw.'
      }} />;
  }
};

export default AppointmentSecurity;
