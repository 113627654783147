import React from 'react';

import { Dispatcher } from '../services/Dispatcher';
import { Injector } from '../services/Injector';
import { WidgetRegistry } from '../services/WidgetRegistry';
import { setupDataStores, setupDataStoreTypes } from '../utils/datastores';
import { setupLayoutProvider } from '../utils/providers';

export const RenderContext = React.createContext({});

export const RenderProvider = ({
	debug = false,
	rootElement,
	context,
	datastores,
	datastoreTypes,
	layoutProviderTypes,
	layoutProvider,
	widgetFallback,
	widgets,
	children,
}) => {
	const injector = new Injector();
	// Register Widget Registry
	const widgetRegistry = injector.get(WidgetRegistry);
	// Register Dispatcher
	const dispatcher = injector.get(Dispatcher);

	// Set up different datastore types
	setupDataStoreTypes(datastoreTypes, injector);

	// Register & setup data stores in the injector
	setupDataStores(datastores, injector, context);

	// Register a layout provider for dynamic layout, if the configuration describes one
	setupLayoutProvider(layoutProviderTypes, layoutProvider, injector);

	// Set fallback widget
	if (widgetFallback) {
		widgetRegistry.setWidgetFallback(widgetFallback);
	}

	// Register widgets in the widget registry
	widgets.forEach((widget) => widgetRegistry.register(widget.type, widget));

	return (
		<RenderContext.Provider value={{
			injector,
			rootElement,
			widgetRegistry,
			dispatcher,
			debug,
		}}>
			{children}
		</RenderContext.Provider>
	);
};
