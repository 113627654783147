import { useEffect, useState, useRef } from "react";
import { Subject, combineLatest } from "rxjs";
import { tap, map, takeUntil } from "rxjs/operators";
import { isObject } from "../utils/is-object";
import { useRenderContext } from "./useRenderContext";

const transformContext = (context) => {
	if (!context) {
		return {};
	}

	return Object.keys(context).reduce((acc, curr) => {
		acc[curr] = {
			...context[curr],
			datastore: "astad-context",
		};

		return acc;
	}, {});
};

export const useDataStores = ({ state, context }, stores) => {
	const { injector, debug } = useRenderContext();
	const isInitialMount = useRef(true);
	const [stateConfig, setStateConfig] = useState({
		...transformContext(context),
		...state,
	});
	const [widgetState, setWidgetState] = useState({});
	const sub$ = new Subject();

	useEffect(() => {
		// avoid setting state during initial render to avoid costly rerender
		if (isInitialMount.current) {
			isInitialMount.current = false;
		} else {
			setStateConfig({
				...transformContext(context),
				...state,
			});
		}
	}, [context, state]);

	useEffect(() => {
		sub$.next(true);

		const selectors = Object.entries(stateConfig).reduce(
			(acc, [name, config]) => {
				const datastore =
					(stores && stores[config.datastore]) ||
					(injector.has(config.datastore) && injector.get(config.datastore));

				if (!datastore) {
					return acc;
				}

				return acc.concat(
					datastore.select(config.selector).pipe(
						tap((value) => {
							if (debug) {
								console.log(
									`From ${config.datastore} select ${config.selector.join(
										"."
									)} as ${JSON.stringify(value)}`
								);
							}
						}),
						map((value) => ({ value, name }))
					)
				);
			},
			[]
		);

		combineLatest(selectors)
			.pipe(
				map((values) =>
					values.reduce((acc, { name, value }) => {
						if (name === "widgetContent") {
							if (isObject(value)) {
								return {
									...acc,
									...value,
								};
							}

							acc["value"] = value;
						} else {
							acc[name] = value;
						}

						return acc;
					}, {})
				),
				takeUntil(sub$)
			)
			.subscribe(setWidgetState);

		return () => {
			sub$.next(true);
			sub$.complete();
		};
	}, [stateConfig]);

	return widgetState;
};
