const ENV_IDENTIFIERS = {
  dev: 'afspraken-o',
  acc: 'afspraken-a',
  local: 'localhost'
};

const getEnvironment = () => {
  if (typeof window !== 'undefined') {
    const currentUrl = window.location.href;

    const env = Object
      .keys(ENV_IDENTIFIERS)
      .find(key => currentUrl.includes(ENV_IDENTIFIERS[key]));
    return env || 'prod';
  }
};

const getGtmCode = () => {
  switch (getEnvironment()) {
    case 'local': return 'GTM-P6JD866';
    case 'dev': return 'GTM-P6JD866';
    case 'acc': return 'GTM-P6JD866';
    default:
      return 'GTM-N5LHXQ';
  }
};

export {
  getEnvironment,
  getGtmCode,
};
