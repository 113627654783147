export const FETCH_ALL_THEMES_BEGIN = 'FETCH_ALL_THEMES_BEGIN';
export const FETCH_ALL_THEMES_SUCCESS = 'FETCH_ALL_THEMES_SUCCESS';
export const FETCH_ALL_THEMES_FAILURE = 'FETCH_ALL_THEMES_FAILURE';

export const FETCH_PRODUCTS_BEGIN = 'FETCH_PRODUCTS_BEGIN';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export const FETCH_SINGLE_PRODUCT_BEGIN = 'FETCH_SINGLE_PRODUCT_BEGIN';
export const FETCH_SINGLE_PRODUCT_SUCCESS = 'FETCH_SINGLE_PRODUCT_SUCCESS';
export const FETCH_SINGLE_PRODUCT_FAILURE = 'FETCH_SINGLE_PRODUCT_FAILURE';

export const FETCH_LOCATIONS_BEGIN = 'FETCH_LOCATIONS_BEGIN';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAILURE = 'FETCH_LOCATIONS_FAILURE';

export const FETCH_AVAILABLE_DAYS_BEGIN = 'FETCH_AVAILABLE_DAYS_BEGIN';
export const FETCH_AVAILABLE_DAYS_SUCCESS = 'FETCH_AVAILABLE_DAYS_SUCCESS';
export const FETCH_AVAILABLE_DAYS_FAILURE = 'FETCH_AVAILABLE_DAYS_FAILURE';

export const FETCH_AVAILABLE_TIME_SLOTS_BEGIN = 'FETCH_AVAILABLE_TIME_SLOTS_BEGIN';
export const FETCH_AVAILABLE_TIME_SLOTS_SUCCESS = 'FETCH_AVAILABLE_TIME_SLOTS_SUCCESS';
export const FETCH_AVAILABLE_TIME_SLOTS_FAILURE = 'FETCH_AVAILABLE_TIME_SLOTS_FAILURE';

export const CREATE_NEW_APPOINTMENT_BEGIN = 'CREATE_NEW_APPOINTMENT_BEGIN';
export const CREATE_NEW_APPOINTMENT_SUCCESS = 'CREATE_NEW_APPOINTMENT_SUCCESS';
export const CREATE_NEW_APPOINTMENT_FAILURE = 'CREATE_NEW_APPOINTMENT_FAILURE';

export const UPDATE_APPOINTMENT_BEGIN = 'UPDATE_APPOINTMENT_BEGIN';
export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS';
export const UPDATE_APPOINTMENT_FAILURE = 'UPDATE_APPOINTMENT_FAILURE';

export const FETCH_APPOINTMENT_BEGIN = 'FETCH_APPOINTMENT_BEGIN';
export const FETCH_APPOINTMENT_SUCCESS = 'FETCH_APPOINTMENT_SUCCESS';
export const FETCH_APPOINTMENT_FAILURE = 'FETCH_APPOINTMENT_FAILURE';

export const DELETE_APPOINTMENT_BEGIN = 'DELETE_APPOINTMENT_BEGIN';
export const DELETE_APPOINTMENT_SUCCESS = 'DELETE_APPOINTMENT_SUCCESS';
export const DELETE_APPOINTMENT_FAILURE = 'DELETE_APPOINTMENT_FAILURE';

export const RESET_APPOINTMENT = 'RESET_APPOINTMENT';
export const CLEAR_APPOINTMENT_ERRORS = 'CLEAR_APPOINTMENT_ERRORS';

export const FETCH_DURATION_BEGIN = 'FETCH_DURATION_BEGIN';
export const FETCH_DURATION_SUCCESS = 'FETCH_DURATION_SUCCESS';
export const FETCH_DURATION_FAILURE = 'FETCH_DURATION_FAILURE';

export const FETCH_APPOINTMENTS_BEGIN = 'FETCH_APPOINTMENTS_BEGIN';
export const FETCH_APPOINTMENTS_SUCCESS = 'FETCH_APPOINTMENTS_SUCCESS';
export const FETCH_APPOINTMENTS_FAILURE = 'FETCH_APPOINTMENTS_FAILURE';

export const fetchAllThemesBegin = () => ({
  type: FETCH_ALL_THEMES_BEGIN
});

export const fetchAllThemesSuccess = (themes) => ({
  type: FETCH_ALL_THEMES_SUCCESS,
  payload: { themes }
});

export const fetchAllThemesFailure = (error) => ({
  type: FETCH_ALL_THEMES_FAILURE,
  payload: { error }
});

export const fetchProductsBegin = () => ({
  type: FETCH_PRODUCTS_BEGIN
});

export const fetchProductsSuccess = (products) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: { products }
});

export const fetchProductsFailure = (error) => ({
  type: FETCH_PRODUCTS_FAILURE,
  payload: { error }
});

export const fetchSingleProductBegin = () => ({
  type: FETCH_SINGLE_PRODUCT_BEGIN
});

export const fetchSingleProductSuccess = (product) => ({
  type: FETCH_SINGLE_PRODUCT_SUCCESS,
  payload: { product }
});

export const fetchSingleProductFailure = (error) => ({
  type: FETCH_SINGLE_PRODUCT_FAILURE,
  payload: { error }
});

export const fetchLocationsBegin = () => ({
  type: FETCH_LOCATIONS_BEGIN
});

export const fetchLocationsSuccess = (locations = []) => ({
  type: FETCH_LOCATIONS_SUCCESS,
  payload: { locations: locations.sort((a, b) => a.name.localeCompare(b.name)) }
});

export const fetchLocationsFailure = (error) => ({
  type: FETCH_LOCATIONS_FAILURE,
  payload: { error }
});

export const fetchAvailableDaysBegin = () => ({
  type: FETCH_AVAILABLE_DAYS_BEGIN
});

export const fetchAvailableDaysSuccess = (dates) => ({
  type: FETCH_AVAILABLE_DAYS_SUCCESS,
  payload: { dates }
});

export const fetchAvailableDaysFailure = (error) => ({
  type: FETCH_AVAILABLE_DAYS_FAILURE,
  payload: { error }
});

export const fetchAvailableTimeSlotsBegin = () => ({
  type: FETCH_AVAILABLE_TIME_SLOTS_BEGIN
});

export const fetchAvailableTimeSlotsSuccess = (slots = []) => ({
  type: FETCH_AVAILABLE_TIME_SLOTS_SUCCESS,
  payload: { slots: slots.sort((a, b) => a.location.name.localeCompare(b.location.name)) }
});

export const fetchAvailableTimeSlotsFailure = (error) => ({
  type: FETCH_AVAILABLE_TIME_SLOTS_FAILURE,
  payload: { error }
});

export const createNewAppointmentBegin = () => ({
  type: CREATE_NEW_APPOINTMENT_BEGIN
});

export const createNewAppointmentSuccess = (appointment) => ({
  type: CREATE_NEW_APPOINTMENT_SUCCESS,
  payload: { appointment }
});

export const createNewAppointmentFailure = (error) => ({
  type: CREATE_NEW_APPOINTMENT_FAILURE,
  payload: { error }
});

export const updateAppointmentBegin = () => ({
  type: UPDATE_APPOINTMENT_BEGIN
});

export const updateAppointmentSuccess = (appointment) => ({
  type: UPDATE_APPOINTMENT_SUCCESS,
  payload: { appointment }
});

export const updateAppointmentFailure = (error) => ({
  type: UPDATE_APPOINTMENT_FAILURE,
  payload: { error }
});

export const fetchAppointmentBegin = () => ({
  type: FETCH_APPOINTMENT_BEGIN
});

export const fetchAppointmentSuccess = (appointment) => ({
  type: FETCH_APPOINTMENT_SUCCESS,
  payload: { appointment }
});

export const fetchAppointmentFailure = (error) => ({
  type: FETCH_APPOINTMENT_FAILURE,
  payload: { error }
});

export const deleteAppointmentBegin = () => ({
  type: DELETE_APPOINTMENT_BEGIN
});

export const deleteAppointmentSuccess = (appointment) => ({
  type: DELETE_APPOINTMENT_SUCCESS,
  payload: { appointment }
});

export const deleteAppointmentFailure = (error) => ({
  type: DELETE_APPOINTMENT_FAILURE,
  payload: { error }
});

export const resetAppointment = () => ({
  type: RESET_APPOINTMENT
});

export const clearAppointmentErrors = () => ({
  type: CLEAR_APPOINTMENT_ERRORS
});

export const fetchAppointmentsBegin = () => ({
  type: FETCH_APPOINTMENTS_BEGIN
});

export const fetchAppointmentsSuccess = (appointments) => ({
  type: FETCH_APPOINTMENTS_SUCCESS,
  payload: { appointments }
});

export const fetchAppointmentsFailure = (error) => ({
  type: FETCH_APPOINTMENTS_FAILURE,
  payload: { error }
});

export const fetchDurationBegin = () => ({
  type: FETCH_DURATION_BEGIN
});

export const fetchDurationSuccess = (duration) => ({
  type: FETCH_DURATION_SUCCESS,
  payload: { duration }
});

export const fetchDurationFailure = (error) => ({
  type: FETCH_DURATION_FAILURE,
  payload: { error }
});
